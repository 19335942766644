import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import { setIsOpen, addToQueue } from "../features/widgetStateSlice.js";
import { EVENT_USER_INPUT } from "../utils/controller.js";

import FQList from "./fq/FQList.js";
import FQTitle from "./fq/FQTitle.js";

export const FQWindowStyles = styled.div`
  z-index: 9999;
  width: calc(100% - 40px);
  max-width: 400px;
  border-radius: 4px;
  position: fixed;
  -webkit-box-shadow: ${(props) => props.theme.boxShadow};
  box-shadow: ${(props) => props.theme.boxShadow};
  bottom: ${(props) =>
    `${
      props.theme.chatOuterSpacing + 50 + 20
    }px`}; /* outerSpacing + trigger height + space between */
  right: 0;
  margin: 0 ${(props) => `${props.theme.chatOuterSpacing}px`};
  opacity: ${(props) => (props.$isFeaturesOpen ? "1" : "0")};
  transition-delay: 0.3s !important;
  visibility: ${(props) => (props.$isFeaturesOpen ? "visible" : "hidden")};
  @media (max-width: 490px) {
    margin: 0 20px;
    bottom: ${(props) =>
      `${
        20 + 50 + 20
      }px`}; /* outerSpacingCapped + trigger height + space between */
    max-width: calc(100% - 40px);
  }

  ${(props) =>
    props.theme.chatAlignment === "left" &&
    `
      @media (min-width: 491px) {
        left: 0;
      }
    `};
`;

export const FQContent = styled.div`
  margin-top: 20px;
  color: ${(props) => props.theme.systemMessageText};
  font-size: 14px;
  line-height: 1.29;
  p:first-of-type {
    margin: 0 0 8px;
  }
  & > div {
    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }
`;

export default function FQ(props) {
  const { questions, modal, isFeaturesOpen, isRelevant, isProcessing } = props;
  const dispatch = useDispatch();

  return (
    <>
      {modal ? (
        <FQWindowStyles $isFeaturesOpen={isFeaturesOpen}>
          <FQTitle title={questions.title} />
          <FQList questions={questions} />
        </FQWindowStyles>
      ) : (
        <FQContent>
          <p>
            <b>{questions.title}</b>
          </p>

          {questions.questions.map(function (question, index) {
            return (
              <div key={question.id}>
                <a
                  href={question.content_url}
                  target="_blank"
                  // onClick={() => {
                  //   if (isProcessing) {
                  //     console.log("Click ignored, processing");
                  //     return false;
                  //   }
                  //   dispatch(
                  //     addToQueue({ name: EVENT_USER_INPUT, text: question })
                  //   );
                  //   dispatch(setIsOpen(true));
                  // }}
                >
                  {question.title}
                </a>
              </div>
            );
          })}
        </FQContent>
      )}
    </>
  );
}
