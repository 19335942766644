import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { SystemMessageStyle } from "./MessageStyles.js";
import Button from "../../Button.js";
import BotFace from "../../icons/BotFace.js";
import { EVENT_FEEDBACK_RESPONSE } from "../../../utils/controller.js";
import { addToQueue } from "../../../features/widgetStateSlice.js";
// ----------------------------------------------------------------------

const BinaryResponseMessageStyle = styled(SystemMessageStyle)``;

const ButtonContainerStyle = styled.div`
  margin-top: 19px;
  button:first-of-type {
    margin-right: 10px;
  }
`;

export default function BinaryResponseMessage(props) {
  const { isRelevant } = props; // the isRelevant prop governs whether the buttons are clickable or if they are disabled (with the corresponding style)
  const dispatch = useDispatch();
  const themeContext = useTheme();

  return (
    <BinaryResponseMessageStyle>
      <BotFace />
      <div className="chat-bubble">
        <p>{themeContext.binaryMessageText}</p>
        <ButtonContainerStyle>
          <Button
            text={"Yes"}
            disabled={!isRelevant}
            onClick={() =>
              dispatch(
                addToQueue({ name: EVENT_FEEDBACK_RESPONSE, response: true })
              )
            }
          />
          <Button
            text={"No"}
            disabled={!isRelevant}
            onClick={() =>
              dispatch(
                addToQueue({ name: EVENT_FEEDBACK_RESPONSE, response: false })
              )
            }
          />
        </ButtonContainerStyle>
      </div>
    </BinaryResponseMessageStyle>
  );
}
