import { useState, useRef, useEffect } from "react";
import { throttle as _throttle } from "lodash";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/public-sans/400.css";
import "@fontsource/public-sans/500.css";
// import useResizeObserver from "use-resize-observer";
import { ThemeProvider } from "@emotion/react";
import styled from "@emotion/styled";

// components
import {
  StyledPlaceholderSection,
  StyledPlaceholderHeader,
  StyledPlaceholderContainer,
  StyledGroupedDivs,
} from "./theme.js";
import WidgetFixedHeader from "./components/WidgetFixedHeader.js";
import WidgetSwitch from "./components/WidgetSwitch.js";
import WidgetPopover from "./components/WidgetPopover.js";
import fourtyTwoQService from "./services/42q/fourty_two_q_service.js";

const WIDGETAVAILABILITY_REFRESH_TIME = 60 * 1000; // 60s refresh time

export default function AppDemo(props) {
  const { mode, chatType, appId } = props;
  const [popover, setPopover] = useState(true);
  const fixedRef = useRef(null);
  const [alertFired, setAlertFired] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const fixedHeight = fixedRef.current.offsetHeight;
      const contentContainer = document.querySelector(".shifted-content");
      contentContainer.style.paddingTop = `${fixedHeight}px`;
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [popover]);

  useEffect(() => {
    if (alertFired) {
      alert(
        "Permission denied.\nYou need to be logged in and have access to the relevant Project to access the Widget demo page."
      );
      window.location.href = "https://www.42q.ai";
    }
  }, [alertFired]);

  useEffect(() => {
    if (!appId) return;
    // fetch from API
    let myTimer = null;

    // refresh
    const checkFun = () => {
      console.log("Checking access...");
      fourtyTwoQService
        .widget_demo_available(appId)
        .catch((error) => {
          console.log("ERROR", error);
          // any error just redirect to 42Q homepage
          if (!alertFired) {
            setAlertFired(true);
          }
        })
        .finally(() => {
          // loop
          myTimer = setTimeout(checkFun, WIDGETAVAILABILITY_REFRESH_TIME);
        });
    };

    checkFun();

    return () => {
      clearTimeout(myTimer);
    };
  }, [appId]);

  const closePopover = () => {
    setPopover(false);
  };
  return (
    <>
      <WidgetFixedHeader ref={fixedRef}>
        <WidgetSwitch />
        {popover && <WidgetPopover mode={mode} setOpen={closePopover} />}
      </WidgetFixedHeader>

      {/* Hello world! I am a demo {mode} {chatType} */}
      <StyledPlaceholderSection className="shifted-content">
        <StyledPlaceholderHeader>
          <StyledPlaceholderContainer>
            <div className="placeholder-heading" />
          </StyledPlaceholderContainer>
        </StyledPlaceholderHeader>

        <StyledPlaceholderContainer>
          <StyledGroupedDivs>
            <div />
            <div />
            <div />
            <div />
            <div />
          </StyledGroupedDivs>
        </StyledPlaceholderContainer>
      </StyledPlaceholderSection>
    </>
  );
}
