export default function Eyes() {
  return (
    <svg width="17px" height="7px" viewBox="0 0 17 7" id="eyes-svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1387, -876)" fill="#FFFFFF">
          <g transform="translate(1020, 194)">
            <g transform="translate(350, 660)">
              <g transform="translate(17, 22)">
                <rect
                  x="0"
                  y="0"
                  width="6.66666667"
                  height="6.66666667"
                  rx="1.66666667"
                ></rect>
                <rect
                  x="10"
                  y="0"
                  width="6.66666667"
                  height="6.66666667"
                  rx="1.66666667"
                ></rect>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
