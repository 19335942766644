import { createSlice } from "@reduxjs/toolkit";
import { isEqual as _isEqual } from "lodash";

// import { HYDRATE } from "next-redux-wrapper";

export const initialState = {
  isOpen: false,
  isFeaturesOpen: true,
  channelName: "",
  loadedFrames: {},
  frameSizes: {},
  nextEventId: 1, // initial event ID
  queue: [],
};

export const ALL_FRAMES = ["messages", "trigger", "fqs", "imt"]; //inline mobile trigger

ALL_FRAMES.forEach((frame) => {
  initialState.frameSizes[frame] = {
    width: 0,
    height: 0,
  };
});

export const widgetStateSlice = createSlice({
  name: "widgetStateSlice",
  initialState,
  reducers: {
    addToQueue: (state, action) => {
      const event = { ...action.payload, eventId: state.nextEventId };
      state.queue = [...state.queue, event];
      state.nextEventId = state.nextEventId + 1;
    },
    clearQueue: (state, action) => {
      state.queue = [];
    },
    setIsOpen: (state, action) => {
      const isOpen = action.payload === true;
      state.isOpen = isOpen;
      // hide featured questions if necessary
      if (isOpen) state.isFeaturesOpen = false;
    },
    setIsFeaturesOpen: (state, action) => {
      state.isFeaturesOpen = action.payload === true;
    },
    setFrameLoaded: (state, action) => {
      if (ALL_FRAMES.includes(action.payload)) {
        state.loadedFrames[action.payload] = true;
      } else {
        throw new Error(`Invalid frame ${action.payload}`);
      }
    },
    // setFramePosition: (state, action) => {
    //   if (ALL_FRAMES.includes(action.payload.name)) {
    //     const payloadNew = { ...action.payload };
    //     delete payloadNew.name;
    //     state.framePositions[action.payload.name] = payloadNew;
    //   } else {
    //     throw new Error(`Invalid frame ${action.payload}`);
    //   }
    // },
    setFrameSize: (state, action) => {
      if (ALL_FRAMES.includes(action.payload.name)) {
        state.frameSizes[action.payload.name] = {
          width: action.payload.width,
          height: action.payload.height,
        };
      } else {
        throw new Error(`Invalid frame ${action.payload}`);
      }
    },
    // setFramePositions: (state, action) => {
    //   // used to set state when going between iframe and parent
    //   const payloadNew = { ...action.payload };

    //   const payloadKeys = Object.keys(payloadNew).sort();
    //   const frameKeys = [...ALL_FRAMES].sort();
    //   if (_isEqual(payloadKeys, frameKeys)) {
    //     state.framePositions = action.payload;
    //   } else {
    //     throw new Error(`Invalid frame ${action.payload}`);
    //   }
    // },
    // setFrameVisibility: (state, action) => {
    //   const [frame, visState] = action.payload;
    //   if (ALL_FRAMES.includes(frame)) {
    //     state.framePositions[frame].visible = visState === true;
    //   } else {
    //     throw new Error(`Invalid frame ${frame}`);
    //   }
    // },
  },

  // // Special reducer for hydrating the state. Special case for next-redux-wrapper
  // extraReducers: {
  //   [HYDRATE]: (state, action) => {
  //     return {
  //       ...state,
  //       ...action.payload.auth,
  //     };
  //   },
  // },
});

export const {
  setIsOpen,
  setIsFeaturesOpen,
  setFrameLoaded,
  setFrameVisibility,
  setFrameSize,
  addToQueue,
  clearQueue,
} = widgetStateSlice.actions;

export default widgetStateSlice.reducer;
