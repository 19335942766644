import styled from "@emotion/styled";
import { GenericMessageStyle } from "./MessageStyles.js";
import DotLoader from "../DotLoader.js";
import BotFace from "../../icons/BotFace.js";

const LoadingResponseStyle = styled(GenericMessageStyle)``;

export default function LoadingResponse(props) {
  return (
    <LoadingResponseStyle>
      <BotFace />
      <DotLoader size={"small"} />
    </LoadingResponseStyle>
  );
}
