import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { useContext } from "react";
import { SystemMessageStyle } from "./MessageStyles.js";
import BotFace from "../../icons/BotFace.js";
import parse from "html-react-parser";

// ----------------------------------------------------------------------

const SearchResultsMessageStyle = styled(SystemMessageStyle)``;

const SearchResultsLinkStyle = styled.div`
  margin: 10px 0;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export default function SearchResultsMessage(props) {
  const { articles, flagged } = props;
  const themeContext = useTheme();

  return (
    <SearchResultsMessageStyle>
      <BotFace />
      <div className="chat-bubble">
        {flagged || articles.length == 0 ? (
          <>
            {themeContext.chatMode === "full" &&
              parse(themeContext.noContentIdentifiedNoSilos)}
            {themeContext.chatMode !== "full" && (
              <>
                Sorry I can't find anything that relates to that. You can try
                rephrasing your question or{" "}
                {themeContext.handoverLinkLinkHref && (
                  <a href={themeContext.handoverLinkLinkHref} target="_blank">
                    {themeContext.handoverLinkLabel}
                  </a>
                )}
                {!themeContext.handoverLinkLinkHref && (
                  <a>{themeContext.handoverLinkLabel}</a>
                )}
                .
              </>
            )}
          </>
        ) : (
          <>
            {" "}
            <p>
              I've found the following results that may relate to your question:
            </p>
            {articles.map((article, index) => {
              return (
                <SearchResultsLinkStyle key={index}>
                  <a
                    target="_blank"
                    href={article.content_url ? `${article.content_url}` : "#"}
                  >
                    {article.content_title}
                  </a>
                </SearchResultsLinkStyle>
              );
            })}
          </>
        )}
      </div>
    </SearchResultsMessageStyle>
  );
}
