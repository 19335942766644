import styled from "@emotion/styled";
import { forwardRef } from "react";

const StyledFixedHeader = styled.div`
  position: fixed;
  z-index: 999;
  width: 100%;
`;

const WidgetFixedHeader = forwardRef(({ children }, ref) => {
  return <StyledFixedHeader ref={ref}>{children}</StyledFixedHeader>;
});

export default WidgetFixedHeader;
