import styled from "@emotion/styled";

export const DEMO_THEME = {
  black: "#000000",
  white: "#FFFFFF",
  grey_600: "#637381",
  grey_800: "#212b36",
  border: "#9f9f9f",
  primary_main: "#00ab55",
};

export const Link = styled.a`
  color: ${DEMO_THEME.primary_main};
  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

export const StyledPlaceholderSection = styled.div`
  opacity: 0.4;
  background-color: #f1f1f1;
  overflow: hidden;
`;

export const StyledPlaceholderHeader = styled.div`
  background-color: #c8c8c8;
  padding: 22px 0;
  .placeholder-heading {
    width: 160px;
    height: 15px;
    background-color: #e3e3e3;
  }
`;

export const StyledPlaceholderContainer = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 20px;
`;

export const StyledGroupedDivs = styled.div`
  width: 100%;
  margin: 43px 0;
  &.no-mt {
    margin-top: 0;
  }
  div {
    background: #e3e3e3;
    width: 100%;
    height: 15px;
    margin: 10px 0;
    &:last-of-type {
      width: 90%;
    }
  }
`;

export const StyledPlaceholderFooter = styled.div`
  background-color: #c8c8c8;
  height: 60px;
`;
