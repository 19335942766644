import styled from "@emotion/styled";
import { UserMessageStyle } from "./MessageStyles.js";
// ----------------------------------------------------------------------

const UserResponseMessageStyle = styled(UserMessageStyle)``;

export default function UserResponseMessage(props) {
  const { text } = props;
  return (
    <UserResponseMessageStyle>
      <div className="chat-bubble">{text}</div>
    </UserResponseMessageStyle>
  );
}
