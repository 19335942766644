import styled from "@emotion/styled";
import { DEMO_THEME, Link } from "../theme.js";
import { IconInactiveBot } from "../icons/icons.js";

const StyledWidgetInactive = styled.div`
  background-color: ${DEMO_THEME.white};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  svg {
    position: relative;
    top: -17px;
  }
  footer {
    position: absolute;
    padding-bottom: 36px;
    bottom: 0;
    right: 0;
    left: 0;
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    line-height: 1.83;
    @media (max-width: 600px) {
      padding-bottom: 25px;
    }
  }
`;

export default function WidgetInactive() {
  return (
    <StyledWidgetInactive>
      <IconInactiveBot />
      <footer>
        <Link
          target="_blank"
          href="https://kb.42q.ai/help/widget/what-happens-to-the-widget-if-a-workspaces-credit-balance-is-zero/"
        >
          Why am I seeing this?
        </Link>
      </footer>
    </StyledWidgetInactive>
  );
}
