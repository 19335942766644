export default function Logo() {
  return (
    <svg id="logo-svg" viewBox="0 0 524.77 149.19">
      <path d="m104.43,0h-44.97L0,88.02v28.56h73.95v32.61h30.48v-32.61l20.89-.21v-28.34l-20.89-.21V.01h0Zm-30.48,87.81h-41.34l39.43-59.46h1.91v59.46Z" />
      <g>
        <path d="m248.28.02c-32.51,0-52.82,17.2-54.32,46.01l-.16,3.05h28.71l.28-2.6c1.25-13.17,9.42-21.19,24.03-21.19,18.24,0,21.6,9.97,21.6,17.28,0,13.43-6.9,19.21-28.7,35.45-14.93,10.77-29.91,22.1-39.46,31.99h42.5c5.17-5.12,13.27-10.44,20.72-15.86,27.14-19.1,36.37-30.51,36.37-51.73S286.3,0,248.28,0h0Z" />
        <rect x="200.27" y="121.17" width="42.5" height="27.99" />
        <rect x="254.5" y="121.17" width="42.5" height="27.99" />
      </g>
      <path d="m501.69,121.15c9.8-12.57,15.62-28.72,15.62-46.56C517.31,32.15,484.82.02,442.71.02s-74.39,32.13-74.39,74.57,32.29,74.58,74.39,74.58c.35,0,.7,0,1.05-.01h81.01v-28h-23.09.01Zm-103.93-46.56c0-26.98,19.21-46.55,44.96-46.55s44.97,19.57,44.97,46.55-19.21,46.56-44.97,46.56-44.96-19.57-44.96-46.56h0Z" />
    </svg>
  );
}
