import styled from "@emotion/styled";
import Logo from "../icons/Logo.js";

const BadgeStyle = styled.div`
  margin-top: auto;
  width: 100%;

  background-color: ${(props) => props.theme.badgeBg};
  margin-top: 10px;
  text-align: center;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    cusrsor: pointer;
    text-decoration: none;
    text-align: center;
    font-family: ${(props) => props.theme.font};
    font-size: 12px;
    color: ${(props) => props.theme.badgeText};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s;
    &:hover,
    &:focus {
      color: ${(props) => props.theme.badgeHoverText};
      svg {
        fill: ${(props) => props.theme.badgeHoverText};
      }
    }
    &:focus {
      outline: none;
    }
    &:focus-visible {
      outline: ${(props) => props.theme.focus};
    }
  }
  svg {
    width: 32px;
    height: 14px;
    margin-right: 9px;
    fill: ${(props) => props.theme.badgeText};
    transition: fill 0.3s;
  }
`;

export default function Badge() {
  return (
    <BadgeStyle>
      <a href="https://www.42q.ai/" target="_blank">
        <Logo />
        Powered by 42 Questions
      </a>
    </BadgeStyle>
  );
}
