const DEBUG_DEMO_HOSTS = [
  "w",
  "y",
  "42q-widget-dev.vercel.app",
  "42q-widget.vercel.app",
  "localhost",
  "widget.42q.local",
  "widget.42q-dev.framewurk.io",
];

export const is_debug = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const debug_val = searchParams.get("debug_42qwidget"); // price_descending

  if (debug_val === "false") {
    return false;
  }

  return (
    DEBUG_DEMO_HOSTS.includes(window.location.hostname) || debug_val === "true"
  );
};
