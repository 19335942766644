import axios from "axios";
// import authService from "./auth/auth.service";
// import { storeCSRFToken } from "../features/loginStateSlice";
import { is_debug } from "../utils/debug.js";

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.headers.get["Content-Type"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";

const baseURL = process.env.REACT_APP_STATE_BACKEND_API_URL
  ? process.env.REACT_APP_STATE_BACKEND_API_URL
  : process.env.NEXT_PUBLIC_HOST_API_URL;
const instance = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: "application/json",
    // "Content-type": "application/json",
  },
  withCredentials: true, // needed for session auth
});

/*
export const setUpInterceptor = (store) => {
  instance.interceptors.request.use((config) => {
    const evt = new Event("request", { bubbles: true, cancelable: false });
    document.dispatchEvent(evt);

    // replace csrf token with prefetched on start app
    if (
      config.method === "post" ||
      config.method === "put" ||
      config.method === "delete"
    ) {
      const state = store.getState();
      console.log(state);
      const csrfToken = state.loginState.csrftoken;

      config.headers = { ...config.headers, ["X-CSRFToken"]: csrfToken };
      // unset properties to prevent setting to default csrf token for cookie
      config.xsrfHeaderName = "X-CSRFToken";
      config.xsrfCookieName = "csrftoken";
    }

    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const status = error.response ? error.response.status : null;

      // update csrf token
      if (status === 403) {
        console.log("Updating CSRF token...");
        authService.fetchToken().then(({ data }) => {
          store.dispatch(storeCSRFToken(data));
          return Promise.reject(error);
        });
        // how to make this work?
      }
      return Promise.reject(error);
    }
  );

  console.log("Axios initialised, API URL:", baseURL);
};
*/
export const setUpInterceptor = (store) => {
  if (is_debug()) console.log("Axios initialised, API URL:", baseURL);
};

export default instance;
