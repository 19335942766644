import styled from "@emotion/styled";
import { DEMO_THEME } from "../theme.js";

const StyledSwitchContainer = styled.div`
  background-color: ${DEMO_THEME.white};
  color: ${DEMO_THEME.grey_800};
  font-family: Public Sans;
  font-size: 14px;
  font-weight: 1.71;
  border-bottom: 1px solid ${DEMO_THEME.border};
  padding: 18px;
  display: flex;
  justify-content: center;
`;

const StyledSwitchButon = styled.button`
  cursor: pointer;
  flex-shrink: 0;
  margin: 0 10px;
  width: 33px;
  height: 20px;
  overflow: hidden;
  color: transparent;
  border: 0;
  border-radius: 10px;
  background-color: ${DEMO_THEME.primary_main};
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: ${DEMO_THEME.white};
    top: 3px;
    left: 3px;
  }
  &.inline:after {
    transform: translateX(12px);
  }
`;

export default function WidgetSwitch() {
  const queryParameters = new URLSearchParams(window.location.search);
  const type = queryParameters.get("chatType");

  function handleClick(e) {
    e.preventDefault();
    const urlParams = new URLSearchParams(window.location.search);

    // ensure params are in
    if (![...urlParams.keys()].includes("chatType")) {
      urlParams.append("chatType", "overlay");
    }
    if (![...urlParams.keys()].includes("mode")) {
      urlParams.append("mode", "demo");
    }

    const newParamsArr = [...urlParams.entries()].map(([key, value]) => {
      if (key === "chatType")
        return [key, value === "overlay" ? "inline" : "overlay"];
      return [key, value];
    });

    const urlParamsProc = new URLSearchParams(newParamsArr);

    // redirect
    window.location.href =
      window.location.href.split("?")[0] + "?" + urlParamsProc.toString();
  }
  return (
    <StyledSwitchContainer>
      <span>Overlaid Widget</span>
      <StyledSwitchButon className={type} onClick={handleClick}>
        toggle widget
      </StyledSwitchButon>
      <span>Inline Widget</span>
    </StyledSwitchContainer>
  );
}
