import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { useContext } from "react";
import { useDispatch } from "react-redux";

import Button from "../Button.js";
import Logo from "../icons/Logo.js";
import Close from "../icons/Close.js";
import { setIsOpen } from "../../features/widgetStateSlice.js";
const ChatHeaderStyle = styled.div`
  background-color: ${(props) => props.theme.background};
  ${(props) =>
    props.theme.chatType === "inline" && !props.theme.chatInlineHeaderXL
      ? `
      @media (min-width: 491px) {
        display: none
      }
    `
      : ""};
`;

const ChatHeaderInnerStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 62px;
  box-sizing: border-box;
  padding: 0 20px;
  max-width: 1180px;
  margin: 0 auto;
  position: relative;

  svg {
    fill: ${(props) => props.theme.headerText};
    height: 22px;
    flex-shrink: 0;
    g {
      fill: ${(props) => props.theme.headerText};
    }
  }
  .custom-logo-icon {
    display: none;
    height: 22px;
    content: ${(props) => `url(${props.theme.logo})`};
  }
  ${(props) =>
    props.theme.logo &&
    `
      #logo-svg {
        display: none;
      }
      .custom-logo-icon {
        display: block;
      }
  `}
  .chat-header-close {
    width: 16px;
    height: 16px;
    margin-left: 20px;
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    &:focus-visible {
      outline: ${(props) => props.theme.focus};
    }
    #close-svg {
      width: 14px;
      position: relative;
      top: -3px;
    }
    @media (min-width: 491px) {
      display: none;
    }
    ${(props) => props.$windowSize?.width > 490 && `display: none;`}
  }
`;

const ChatHeaderButtonsStyle = styled.div`
  display: flex;
  align-items: center;
`;

export default function ChatHeader(props) {
  const { withButton, windowSize } = props;
  const themeContext = useTheme();
  const dispatch = useDispatch();

  // console.log("Current theme: ", themeContext);

  return (
    <ChatHeaderStyle>
      <ChatHeaderInnerStyle $windowSize={windowSize}>
        <img className="custom-logo-icon" alt="" />

        <Logo />
        <ChatHeaderButtonsStyle>
          {withButton && (
            <Button
              text={themeContext.buttonText}
              onClick={() => {
                if (
                  themeContext.handoverButtonLinkHref ===
                  "https://www.42q.ai/show-42q-chat"
                ) {
                  window.parent.postMessage(
                    {
                      channelName,
                      name: "open42QChat",
                      mode,
                    },
                    "*"
                  );
                  // dispatch(setIsOpen(false));
                } else {
                  window
                    .open(
                      themeContext.handoverButtonLinkHref,
                      themeContext.handoverButtonTarget
                    )
                    .focus();
                }
              }}
            />
          )}

          <button
            aria-label="Close"
            className="chat-header-close"
            onClick={() => dispatch(setIsOpen(false))}
          >
            <Close />
          </button>
        </ChatHeaderButtonsStyle>
      </ChatHeaderInnerStyle>
    </ChatHeaderStyle>
  );
}
