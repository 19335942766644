import * as Sentry from "@sentry/browser";

import { addToQueue, clearQueue } from "../features/widgetStateSlice.js";
import Markdown from "react-markdown";
import { fourtyTwoQService as fourtyTwoQServiceMock } from "./mock.js";

import fourtyTwoQService, {
  ERROR_INVALID_JOB,
} from "../services/42q/fourty_two_q_service.js";

export const STATE_NOINIT = "noinit";
export const STATE_SILO_SELECT = "silo_select";
export const STATE_READY = "ready";
export const STATE_PROCESSING = "processing";

const ALL_STATES = [
  STATE_NOINIT,
  STATE_SILO_SELECT,
  STATE_READY,
  STATE_PROCESSING,
];

export const EVENT_INIT_NORMAL = "init_normal";
export const EVENT_REINIT_SILOS = "reinit_silos";
export const EVENT_INIT_FQ = "init_fq";
// export const EVENT_INIT_SILOS = "init_silos";
export const EVENT_SILO_RESPONSE_A = "silo_response_a"; // yes or no on the silo select
export const EVENT_SILO_RESPONSE_B = "silo_response_b"; // response after silo is selected
export const EVENT_USER_INPUT = "user_input";
export const EVENT_FEEDBACK_RESPONSE = "feedback_response"; // response user feedback is given
export const EVENT_INIT_RESTORE = "init_restore";

const selectQueue = (state) => state.widgetState.queue;

const performQuery = (
  chatMode,
  appId,
  query,
  previewChatMode,
  siloId,
  privateEndpoints,
  externalHTTP,
  GLOB_DEBUG
) => {
  if (chatMode === "search") {
    if (previewChatMode === "demo") {
      return fourtyTwoQServiceMock.search(appId, query);
    } else {
      return fourtyTwoQService.search(
        appId,
        query,
        siloId,
        privateEndpoints,
        externalHTTP,
        GLOB_DEBUG
      );
    }
  } else if (chatMode === "full") {
    if (previewChatMode === "demo") {
      return fourtyTwoQServiceMock.answers(appId, query);
    } else {
      return fourtyTwoQService.answers(
        appId,
        query,
        siloId,
        privateEndpoints,
        externalHTTP,
        GLOB_DEBUG
      );
    }
  }
};

const FEEDBACK_DELAY = 2500;
const RESPONSE_YES = "Yes.";
const RESPONSE_NO = "No.";

export default class MessagesController {
  constructor(
    store,
    theme,
    frameMode,
    messages,
    setMessages,
    setIsProcessing,
    setAllowUserInput,
    externalHTTP,
    debug
  ) {
    this.store = store;
    this.messages = messages;
    this.setMessages = setMessages;
    this.setIsProcessing = setIsProcessing;
    this.setAllowUserInput = setAllowUserInput;
    this.theme = theme; // theme and settings
    this.frameMode = frameMode;
    this.debug = debug;
    if (this.debug) console.log(`MC for ${this.frameMode}`);
    this.curQueue = null;
    this.init();
    this.state = STATE_NOINIT;
    this.selectedSilo = null;
    this.delayedTimer = null;
    this.externalHTTP = externalHTTP ? externalHTTP : null;
  }

  init() {
    if (this.debug) console.log(`MC: Subscribe`);
    this.eventIdProcessed = 0;
    this.unsubStore = this.store.subscribe(() => this.handleStoreEvent());
    this.curQueue = selectQueue(this.store.getState());
  }

  getSelectedSilo() {
    return this.selectedSilo;
  }

  getState() {
    return this.state;
  }

  handleStoreEvent() {
    if (this.debug) console.log("MC: Store event");
    //    console.log("MC: Store eventa", this);
    let prevQueue = this.curQueue; // TODO do we need array comparison from lodash?
    this.curQueue = selectQueue(this.store.getState());
    if (prevQueue !== this.curQueue && this.curQueue.length > 0) {
      if (this.debug)
        console.log("MC: QUEUE changed and not empty, processing");

      // process queue objects
      this.curQueue.forEach((event) => {
        if (this.debug) console.log("MC: Processing", event);
        this.respondToEvent(event);
      });

      if (this.curQueue.length > 0) {
        if (this.debug) console.log("MC: QUEUE purge");
        this.store.dispatch(clearQueue());
      }
    } else {
      if (this.debug) console.log("MC: QUEUE the same");
    }
  }

  getSystemResponsePreset() {
    // console.log("SEL silo", this.selectedSilo);
    return this.theme.silos.length === 0 // ||
      ? // this.selectedSilo === null ||
        // this.selectedSilo === 0
        ""
      : "group1";
  }

  unsubscribe() {
    if (this.debug) console.log(`MC: Unsubscribe ${this.frameMode}`);
    this.unsubStore();
  }

  delayedFeedbackMessage() {
    this.delayedTimer = setTimeout(() => {
      this.appendMessage({
        type: "BinaryResponseMessage",
      });
    }, FEEDBACK_DELAY);
  }

  respondToUserInput(query, reset = false) {
    // and now start processing etc.
    this.setIsProcessing(true);
    if (reset) {
      this.setMessages((prevMessages) => [
        { type: "UserResponseMessage", text: query },
      ]);
    } else {
      this.setMessages((prevMessages) => [
        ...prevMessages,
        { type: "UserResponseMessage", text: query },
      ]);
    }
    let fireFeedback = true;

    performQuery(
      this.theme.chatMode,
      this.theme.appId,
      query,
      this.theme.previewChatMode,
      this.selectedSilo ? this.selectedSilo.siloId : 0,
      this.theme.privateEndpoints,
      this.externalHTTP,
      this.debug
    )
      .then((data) => {
        if (this.theme.chatMode === "search") {
          if (this.debug) console.log(data.data);

          // check - did we receive a flagged response?

          if (data.data.type === "search_results") {
            this.appendMessage({
              type: "SearchResultsMessage",
              articles: data.data.results,
            });
          } else if (data.data.type == "moderation_flagged") {
            // this.appendMessage({
            //   type: "SystemResponseMessage",
            //   textHTML:
            //     this.getSystemResponsePreset() === "group1"
            //       ? `I am sorry, I am unable to search for this query. Please type another question or <a class='link_flowreset' href='#'></a>.`
            //       : `I am sorry, I am unable to search for this query. Please type another question.`,
            //   // preset: this.getSystemResponsePreset(),
            // });
            this.appendMessage({
              type: "SystemResponseMessage",
              textHTML:
                this.getSystemResponsePreset() === "group1"
                  ? this.selectedSilo
                    ? this.theme.noContentIdentifiedWithSilos.replace(
                        "[selected-content-silo]",
                        this.selectedSilo.siloText
                      )
                    : this.theme.noContentIdentifiedWithSilosNotSelected
                  : this.theme.noContentIdentifiedNoSilos,
            });
            fireFeedback = false;
          } else {
            this.appendMessage({
              type: "SystemResponseMessage",
              textHTML:
                this.getSystemResponsePreset() === "group1"
                  ? `I am sorry, something went wrong. Please try a different query <a class='link_flowreset' href='#'></a>.`
                  : `I am sorry, something went wrong. Please try a different query.`,
              // textHTML: `I am sorry, something went wrong. Please try a different query.`,
              // preset: this.getSystemResponsePreset(),
            });
            fireFeedback = false;
          }
        } else if (this.theme.chatMode === "full") {
          // check what we got

          if (data.data.type === "search_results") {
            // this is used when no content is found
            this.appendMessage({
              type: "SearchResultsMessage",
              articles: data.data.results,
            });
          } else if (data.data?.type === "completion_answer") {
            // this.appendMessage({
            //   type: "SearchResultsMessage",
            //   articles: data.data.results,
            // });

            if (this.debug) console.log(data.data);
            if (this.debug) console.log(data.data.answer);

            if (data.data.answer.error) {
              this.appendMessage({
                type: "SystemResponseMessage",
                textHTML:
                  this.getSystemResponsePreset() === "group1"
                    ? this.selectedSilo
                      ? this.theme.noContentIdentifiedWithSilos.replace(
                          "[selected-content-silo]",
                          this.selectedSilo.siloText
                        )
                      : this.theme.noContentIdentifiedWithSilosNotSelected
                    : this.theme.noContentIdentifiedNoSilos, // `I am sorry, I have not found enough relevant context to answer this query. Please type another question`,
              });
              fireFeedback = false;
            } else {
              this.appendMessage({
                type: "CompletionMessage",
                isFinished: true,
                noArticlesFound: 2,
                answer: data.data.answer_html,
                sourceQuestion: {
                  metadata: { article_path: data.data.content?.url },
                  title: data.data.content?.title,
                },
                agentsAvailable: true,
              });
            }

            if (this.debug) {
              try {
                console.log(
                  `%c \$${data.data.debug_data.price} `,
                  "font-weight: bold; font-size: 35px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8)"
                );
              } catch (e) {}
              try {
                console.log(
                  `%c Step1: Embedding, moderation, search ${data.data.debug_data.timing_data[
                    "step1-search"
                  ].toFixed(2)}s `,
                  "font-weight: bold; font-size: 18px;color: blue;"
                );
                console.log(
                  `%c Step2: Job scheduling delay ${data.data.debug_data.timing_data[
                    "step2-wait"
                  ].toFixed(2)}s `,
                  "font-weight: bold; font-size: 18px;color: blue;"
                );
                console.log(
                  `%c Step3: OpenAI completion ${data.data.debug_data.timing_data[
                    "step3-completion"
                  ].toFixed(2)}s `,
                  "font-weight: bold; font-size: 18px;color: blue;"
                );
              } catch (e) {}
            }
          } else if (data.data?.type == "moderation_flagged") {
            this.appendMessage({
              type: "SystemResponseMessage",
              textHTML:
                this.getSystemResponsePreset() === "group1"
                  ? this.selectedSilo
                    ? this.theme.noContentIdentifiedWithSilos.replace(
                        "[selected-content-silo]",
                        this.selectedSilo.siloText
                      )
                    : this.theme.noContentIdentifiedWithSilosNotSelected
                  : this.theme.noContentIdentifiedNoSilos,
            });
            fireFeedback = false;
          } else {
            this.appendMessage({
              type: "SystemResponseMessage",
              textHTML:
                this.getSystemResponsePreset() === "group1"
                  ? `I am sorry, something went wrong. Please try a different query <a class='link_flowreset' href='#'></a>.`
                  : `I am sorry, something went wrong. Please try a different query.`,
              // textHTML: `I am sorry, something went wrong. Please try a different query.`,
              // preset: this.getSystemResponsePreset(),
            });
            fireFeedback = false;
          }

          if (this.debug) console.log(data);
        }

        if (fireFeedback) {
          this.delayedFeedbackMessage();
        }
      })
      .catch((error) => {
        // alert(error);
        // if (error === ERROR_INVALID_JOB) {
        // } else {
        // }
        console.log("Caught error", error);

        if (error.response?.status === 402) {
          this.appendMessage({
            type: "SystemResponseMessage",
            textHTML: `I am sorry, this chat widget has been disabled.`,
            // preset: this.getSystemResponsePreset(),
          });
        } else {
          this.appendMessage({
            type: "SystemResponseMessage",
            textHTML:
              this.getSystemResponsePreset() === "group1"
                ? `I am sorry, something went wrong. Please try again or <a class='link_flowreset' href='#'></a>.`
                : `I am sorry, something went wrong. Please try again.`,

            // preset: this.getSystemResponsePreset(),
          });
        }

        fireFeedback = false;
        Sentry.captureException(error);
      })
      .finally(() => {
        this.setIsProcessing(false);
        this.state = STATE_READY;
      });
  }

  appendMessage(message) {
    this.setMessages((prevMessages) => [...prevMessages, message]);
  }

  addUserMessage(text) {
    this.appendMessage({
      type: "UserResponseMessage",
      text,
    });
  }

  respondToEvent(event) {
    clearTimeout(this.delayedTimer); // clear feedback message interval

    this.setAllowUserInput(false); // by default no user input allowed
    if (this.debug)
      console.log(`MC: Responding to ${event.name}, silo:${this.selectedSilo}`);
    if (event.name === EVENT_INIT_RESTORE) {
      try {
        // mark start of processing state
        this.state = STATE_PROCESSING;
        this.setAllowUserInput(false);

        console.log("Restoring...", event);
        // restore state

        // controller
        this.selectedSilo = event.payload.controller.selectedSilo; // reset selected silo
        this.state = event.payload.controller.state;

        // messages
        this.setMessages(event.payload.messages);

        // ui state
        this.setAllowUserInput(event.payload.ui.allowUserInput);

        // done - state is restored!
      } catch (e) {
        console.log("UNABLE TO RESTORE STATE!!", e);
        this.store.dispatch(clearQueue());
        this.store.dispatch(addToQueue({ name: EVENT_INIT_NORMAL }));
      }
    } else if (event.name === EVENT_INIT_NORMAL) {
      this.selectedSilo = null; // reset selected silo
      if (this.theme.silos.length === 0) {
        /**
         * Regular or silo init init
         */
        this.state = STATE_PROCESSING;

        // normal init - initialise the messages with a welcome message
        // we wipe anything that is already there
        // we ignore existing messages that are there
        this.setMessages((prevMessages) => [
          { type: "WelcomeMessage", variant: "search" },
        ]);
        this.state = STATE_READY;
        this.setAllowUserInput(true);
      } else {
        /**
         * Init with silo selection
         */
        this.state = STATE_PROCESSING;

        this.setMessages((prevMessages) => [
          { type: "WelcomeMessage", variant: "group" },
        ]);
        this.state = STATE_SILO_SELECT;
        this.setAllowUserInput(false); // user input is not allowed
      }
    } else if (
      event.name === EVENT_REINIT_SILOS &&
      this.theme.silos.length !== 0
    ) {
      /**
       * Using the "start again" option, only allowed when in silo mode
       */
      this.state = STATE_PROCESSING;
      this.selectedSilo = null; // reset selected silo
      this.appendMessage({ type: "WelcomeMessage", variant: "group" });
      this.state = STATE_SILO_SELECT;
      this.setAllowUserInput(false); // user input is not allowed
    } else if (event.name === EVENT_INIT_FQ) {
      /**
       * Init with featured question
       */
      this.selectedSilo = null; // reset selected silo
      this.state = STATE_PROCESSING;
      this.respondToUserInput(event.text, true);
      this.setAllowUserInput(true);
    } else if (event.name === EVENT_SILO_RESPONSE_A) {
      /**
       * Response to silo message
       */
      this.state = STATE_PROCESSING;
      this.setAllowUserInput(false);

      this.addUserMessage(event.response === true ? RESPONSE_YES : RESPONSE_NO);

      if (event.response === false) {
        // silo-specific
        this.appendMessage({
          type: "SystemResponseMessage",
          textHTML:
            "<p>Please enter your question below or <a class='link_flowreset' href='#'></a>.</p>",
          preset: this.getSystemResponsePreset(),
        });

        this.state = STATE_READY;
        this.setAllowUserInput(true);
      } else {
        // not silo specific
        this.appendMessage({
          type: "GroupSelectionMessage",
          groups: this.theme.silos,
        });
        this.state = STATE_SILO_SELECT;
      }
    } else if (event.name === EVENT_SILO_RESPONSE_B) {
      this.selectedSilo = {
        siloId: event.siloId,
        siloText: event.siloText,
      }; // set selected silo

      this.addUserMessage(event.siloText);
      this.appendMessage({
        type: "SystemResponseMessage",
        textHTML: `<p>Please enter your question below about ${this.selectedSilo.siloText} or <a class='link_flowreset' href='#'></a>.</p>`,
        preset: this.getSystemResponsePreset(),
      });
      this.state = STATE_READY;
      this.setAllowUserInput(true);
    } else if (event.name === EVENT_USER_INPUT) {
      /**
       * User input
       */
      if (this.state !== STATE_READY) {
        throw new Error(
          `MC: Invalid state - cannot accept user input when not ready. Current state is ${this.state}`
        );
      }
      this.state = STATE_PROCESSING;
      // similar to the init_fq but we do not reset the messages
      this.respondToUserInput(event.text, false);

      this.setAllowUserInput(true); // the user input is also limited by isProcessing
    } else if (event.name === EVENT_FEEDBACK_RESPONSE) {
      /**
       * Yes/No user feedback
       */

      this.addUserMessage(event.response === true ? RESPONSE_YES : RESPONSE_NO);

      if (event.response === false) {
        this.appendMessage({
          type: "SystemResponseMessage",
          // textHTML: this.selectedSilo
          //   ? `Ok, you can try rephrasing your question related to ${this.selectedSilo.siloText} below`
          //   : `Ok, you can try rephrasing your question`,
          textHTML:
            this.getSystemResponsePreset() === "group1"
              ? this.selectedSilo
                ? this.theme.negativeFeedbackWithSilos.replace(
                    "[selected-content-silo]",
                    this.selectedSilo.siloText
                  ) // negative feedback with silos
                : this.theme.negativeFeedbackWithSilosNotSelected // negative feedback with silos  no silo selected
              : this.theme.negativeFeedbackNoSilos,
        });
      } else {
        this.appendMessage({
          type: "SystemResponseMessage",
          textHTML:
            this.getSystemResponsePreset() === "group1"
              ? this.selectedSilo
                ? this.theme.positiveFeedbackWithSilos.replace(
                    "[selected-content-silo]",
                    this.selectedSilo.siloText
                  ) // positive feedback with silos
                : this.theme.positiveFeedbackWithSilosNotSelected // positive feedback with silos  no silo selected
              : this.theme.positiveFeedbackNoSilos,
        });
      }
      this.setAllowUserInput(true);
    } else {
      throw new Error(`MC: Invalid event ${event.name}`);
    }
  }
}
