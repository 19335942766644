import parse from "html-react-parser";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { useMemo, useContext } from "react";
import { useDispatch } from "react-redux";

import { SystemMessageStyle } from "./MessageStyles.js";
import Button from "../../Button.js";
import BotFace from "../../icons/BotFace.js";
import FQ from "../../FQ.js";

import { EVENT_SILO_RESPONSE_A } from "../../../utils/controller.js";
import { addToQueue } from "../../../features/widgetStateSlice.js";

// ----------------------------------------------------------------------

const WelcomeMessageStyle = styled(SystemMessageStyle)``;

const ButtonConatinerStyle = styled.div`
  padding: 19px 0 8px;
  button:first-of-type {
    margin-right: 10px;
  }
`;

const WelcomeMessageBtnStyle = styled.div`
  margin-top: 19px;
`;

export default function WelcomeMessage(props) {
  const {
    variant,
    isRelevant,
    hideIntro,
    questions,
    showQuestions,
    inlineTriggerHandler,
    isProcessing,
  } = props;
  const dispatch = useDispatch();
  const themeContext = useTheme();

  const todString = useMemo(() => {
    var today = new Date();
    var curHr = today.getHours();

    if (curHr < 12) {
      return "morning";
    } else if (curHr < 18) {
      return "afternoon";
    } else {
      return "evening";
    }
  }, []);

  return (
    <WelcomeMessageStyle>
      <BotFace />
      <div className="chat-bubble">
        {(variant === "search" || variant === "chat") && (
          <>
            {parse(themeContext.welcomeText, {
              replace(domNode) {
                // console.log(domNode);
                if (
                  domNode.attribs &&
                  domNode.attribs.class === "dynamic_salute"
                ) {
                  return <>Good {todString}!</>;
                }
              },
            })}
            {inlineTriggerHandler && (
              <WelcomeMessageBtnStyle>
                <Button
                  onClick={inlineTriggerHandler}
                  text={themeContext.askAQuestionLabel}
                />
              </WelcomeMessageBtnStyle>
            )}

            {questions && showQuestions ? (
              <FQ
                questions={questions}
                showQuestions={showQuestions}
                isRelevant={isRelevant}
                isProcessing={isProcessing}
              />
            ) : (
              ""
            )}
          </>
        )}
        {variant === "group" && (
          <>
            {parse(themeContext.welcomeText, {
              replace(domNode) {
                // console.log(domNode);
                if (
                  domNode.attribs &&
                  domNode.attribs.class === "dynamic_salute"
                ) {
                  return <>Good {todString}!</>;
                }
              },
            })}
            {parse(themeContext.welcomeTextSilos)}
            <ButtonConatinerStyle>
              <Button
                text={"Yes"}
                disabled={!isRelevant}
                onClick={() =>
                  dispatch(
                    addToQueue({ name: EVENT_SILO_RESPONSE_A, response: true })
                  )
                }
              />
              <Button
                text={"No"}
                disabled={!isRelevant}
                onClick={() =>
                  dispatch(
                    addToQueue({ name: EVENT_SILO_RESPONSE_A, response: false })
                  )
                }
              />
            </ButtonConatinerStyle>
          </>
        )}
      </div>
    </WelcomeMessageStyle>
  );
}
