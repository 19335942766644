export function IconInfo({ ...other }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      viewBox="0 0 24 24"
      className="icon-info"
      {...other}
    >
      <path
        fill="#637381"
        d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2m1 14a1 1 0 0 1-2 0v-5a1 1 0 0 1 2 0Zm-1-7a1 1 0 1 1 1-1a1 1 0 0 1-1 1"
      ></path>
    </svg>
  );
}

export function IconClose({ ...other }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      viewBox="0 0 24 24"
      className="icon-close"
      {...other}
    >
      <path
        fill="#637381"
        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"
      ></path>
    </svg>
  );
}

export function IconInactiveBot({ ...other }) {
  return (
    <svg
      height="100"
      viewBox="0 0 50 100"
      width="50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#c8c8c8" transform="translate(0 42)">
          <path
            d="m50 50v7.2322159h-10z"
            transform="matrix(1 0 0 -1 0 107.2322)"
          />
          <path d="m4 0h42c2.209139-0 4 1.790861 4 4v46h-46c-2.209139 0-4-1.790861-4-4v-42c-0-2.209139 1.790861-4 4-4z" />
        </g>
        <g fill="#fff">
          <rect height="1" rx=".5" width="6.666667" x="17" y="67" />
          <rect height="1" rx=".5" width="6.666667" x="27" y="67" />
        </g>
        <g fill="#c8c8c8" fillRule="nonzero">
          <path
            d="m0 5.784v-.712l2.724-3.792h-2.472v-1.28h4.712v.68l-2.78 3.808h2.732v1.296z"
            transform="translate(24.36 26.216)"
          />
          <path
            d="m0 8.676v-1.068l4.086-5.688h-3.708v-1.92h7.068v1.02l-4.17 5.712h4.098v1.944z"
            transform="translate(14.54 14.324)"
          />
          <path
            d="m0 11.568v-1.424l5.448-7.584h-4.944v-2.56h9.424v1.36l-5.56 7.616h5.464v2.592z"
            transform="translate(24.72 .432)"
          />
        </g>
      </g>
    </svg>
  );
}
