import React from "react";
import ReactDOM from "react-dom/client";
import { isEqual as _isEqual } from "lodash";
import { throttle as _throttle } from "lodash";
import App from "./App.js";
import AppDemo from "./AppDemo.js";
import AppDemoAfter from "./AppDemoAfter.js";
import reportWebVitals from "./reportWebVitals.js";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration.js";
import * as Sentry from "@sentry/browser";

import { Provider } from "react-redux";
import makeStore from "./store/index.js";
import { ALL_FRAMES } from "./features/widgetStateSlice.js";

import { setUpInterceptor } from "./services/http.js";

import {
  selectIsOpen,
  selectIsFeaturesOpen,
  THEME_42Q_TEST,
  buildTheme,
  propagateStoreChange,
  markLoadedInStore,
  MASTER_MODE,
} from "./utils/widget.js";

import { is_debug } from "./utils/debug.js";

import { settingsReady } from "./utils/parent.js";

import fourtyTwoQService from "./services/42q/fourty_two_q_service.js";

// set up Sentry
Sentry.init({
  dsn: "https://d111a72ff9dbf43ece84ba8523041726@o26269.ingest.sentry.io/4506825329541120",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const GLOB_DEBUG = is_debug();
const inIframe = window.location !== window.parent.location;
const framedLoad =
  document.querySelector('meta[name="42q-iframed"]')?.content === "true";
const isParent = !framedLoad; // && !inIframe;

// parse QS params
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const DEMO_HOSTS = [
  "w",
  "y",
  "42q-widget-dev.vercel.app",
  "42q-widget.vercel.app",
  "widget.42q.ai",
  "localhost",
  "widget.42q.local",
  "widget.42q-dev.framewurk.io",
];

const preloadImageWithPromise = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve(img);
    img.onerror = reject;
  });
};

const preloadMultipleImages = async (imagesArray) => {
  try {
    await Promise.all(
      imagesArray.map((src) => {
        if (src) {
          return preloadImageWithPromise(src);
        }
      })
    );
    if (GLOB_DEBUG) console.log("All images have been preloaded");
  } catch (error) {
    console.error("An error occurred while preloading images", error);
  }
};

if (document.currentScript && document.currentScript.src && isParent) {
  /**
   *
   *
   * Parent frame load
   *
   *
   */

  const currentScript = document.currentScript;

  const appId = window.fourtyTwoWidgetSettings?.appId;
  const chatType = window.fourtyTwoWidgetSettings?.chatType;
  const chatMode =
    urlParams.get("mode") === "demo" &&
    DEMO_HOSTS.includes(window.location.hostname)
      ? "demo"
      : "live";
  fourtyTwoQService
    .settings(appId, GLOB_DEBUG)
    .then((settings) => {
      if (GLOB_DEBUG) console.log("Loaded settings", settings);
      settingsReady(
        GLOB_DEBUG,
        currentScript,
        { ...settings, chatType },
        chatMode
      );
    })
    .catch((error) => {
      console.log(`ERROR WHEN FETCHING SETTINGS FOR ${appId}`, error);
    });

  if (!appId) {
    throw new Error("fourtyTwoWidgetSettings appId not found");
  }
} else if (framedLoad && inIframe) {
  /**
   *
   *
   * In-frame load
   *
   *
   */
  if (GLOB_DEBUG) console.log("Framed load");

  // const chatType = ["overlay", "inline"].includes(urlParams.get("chatType"))
  //   ? urlParams.get("chatType")
  //   : "overlay";

  const channelName = document.querySelector(
    'meta[name="42q-channel"]'
  ).content;
  if (GLOB_DEBUG) console.log("Talking on channel", channelName);

  const store = makeStore(channelName, GLOB_DEBUG);
  setUpInterceptor(store);

  const root = ReactDOM.createRoot(document.getElementById("root"));
  const mode = document.querySelector('meta[name="42q-mode"]').content;

  // Only initialise rest after we have received the settings object,
  // and only initialise once
  let initialised = false;

  window.addEventListener("message", function (event) {
    const payload = event.data;

    if (payload.channelName === channelName) {
      if (GLOB_DEBUG)
        console.log("Message received from the parent: ", payload); // Message received from child

      if (payload.name === "settings") {
        if (initialised) return;
        initialised = true;

        if (GLOB_DEBUG) console.log("Setting up settings...", payload);

        const contFun = () => {
          const theme = buildTheme(THEME_42Q_TEST, settings);

          if (GLOB_DEBUG) console.log(`Child ${mode} theme`, theme);

          // only after settings have been received, continue

          // same empty defaults as in wigetStateSlice.js
          // let currentFramePositions = {};

          const markLoaded = markLoadedInStore(store);

          if (ALL_FRAMES.includes(mode)) {
            root.render(
              <React.StrictMode>
                <Provider store={store}>
                  <App frameMode={mode} theme={theme} debug={GLOB_DEBUG} />
                </Provider>
              </React.StrictMode>
            );
            markLoaded(mode);
          } else {
            throw new Error(`ERROR - invalid mode ${mode}`);
          }

          // send again state
          if (mode === MASTER_MODE) {
            // also send it out periodically just in case
            store.subscribe(propagateStoreChange(store));

            const initialUpdate = setInterval(() => {
              // we want to subscribe after everything has settled
              // to avoid pushing too many updates to the parent
              window.parent.postMessage(
                {
                  channelName,
                  name: "isOpen",
                  data: selectIsOpen(store.getState()),
                },
                "*"
              );
              window.parent.postMessage(
                {
                  channelName,
                  name: "isFeaturesOpen",
                  data: selectIsFeaturesOpen(store.getState()),
                },
                "*"
              );
            }, 1000);

            setTimeout(() => {
              clearInterval(initialUpdate);
            }, 3000);
          }
        };

        const settings = payload.data;
        // PRELOAD IMAGES
        const IMAGES = [
          settings.chatTriggerIconClose,
          settings.chatTriggerIconOpen,
          settings.logo,
        ];

        let loaded = false;
        const checkFun = () => {
          if (!loaded) {
            loaded = true;
            contFun();
          }
        };
        preloadMultipleImages(IMAGES).finally(() => setTimeout(checkFun, 500));

        setTimeout(checkFun, 4000); // fire after 4s of trying to load imagery show anyway
      } else if (payload.name === "window_size") {
        // window size packet is handled in the App
      } else if (payload.name === "command-open") {
        // window size packet is handled in the App
      } else {
        throw new Error(`Invalid payload ${payload.name}`);
      }
    }
  });
  // request settings from the parent
  window.parent.postMessage(
    {
      channelName,
      name: "settingsRequest",
      mode,
    },
    "*"
  );
} else {
  throw new Error(`Invalid load mode`);
}

// widget preview bar - how much space would this need?
if (DEMO_HOSTS.includes(window.location.hostname)) {
  // TODO check domains where we show this
  const demoEl = document.getElementById(`42q_demo_el`);
  const demoElAfter = document.getElementById(`42q_demo_el_after`);

  const mode = urlParams.get("mode") === "live" ? "live" : "demo";
  const chatType =
    urlParams.get("chatType") === "inline" ? "inline" : "overlay";

  if (demoEl && demoElAfter) {
    const root = ReactDOM.createRoot(demoEl);
    root.render(
      <React.StrictMode>
        <AppDemo mode={mode} chatType={chatType} appId={appId} />
      </React.StrictMode>
    );
    const root2 = ReactDOM.createRoot(demoElAfter);
    root2.render(
      <React.StrictMode>
        <AppDemoAfter mode={mode} chatType={chatType} />
      </React.StrictMode>
    );
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
