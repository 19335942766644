import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { useContext } from "react";
import Eyes from "./icons/Eyes.js";
import Close from "./icons/Close.js";
// ----------------------------------------------------------------------

const ButtonTriggerStyle = styled.button`
  padding: 0;
  cursor: pointer;
  position: fixed;
  ${(props) =>
    !props.$inFrame
      ? `bottom: ${props.theme.chatOuterSpacing}px;
         right: ${props.theme.chatOuterSpacing}px;`
      : ``}

  z-index: 102;
  border-radius: 4px;
  height: 50px;
  width: 50px;
  outline: none;
  -webkit-appearance: none;
  border: 0;
  -webkit-box-shadow: ${(props) => props.theme.boxShadow};
  box-shadow: ${(props) => props.theme.boxShadow};
  background-color: ${(props) => props.theme.highlight};

  ${(props) =>
    props.theme.chatAlignment === "left" &&
    `
      @media (min-width: 491px) {
        left: ${props.theme.chatOuterSpacing}px;
      }
    `};

  svg g {
    fill: ${(props) => props.theme.btnText};
  }

  #close-svg {
    width: 14px;
    position: relative;
    top: 2px;
  }

  &:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: -7px;
    width: 0;
    height: 0;
    border-top: ${(props) => `10px solid ${props.theme.highlight}`};
    border-left: 15px solid transparent;
  }

  &.custom-open-icon,
  &.custom-closed-icon {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    box-shadow: none;
    svg,
    &:after {
      display: none;
    }
  }

  &.custom-open-icon {
    background-image: ${(props) => `url(${props.theme.chatTriggerIconOpen})`};
  }
  &.custom-closed-icon {
    background-image: ${(props) => `url(${props.theme.chatTriggerIconClose})`};
  }
`;

export default function ChatTrigger(props) {
  const { isOpen, onClick } = props;
  const themeContext = useTheme();
  function customTrigger() {
    if (themeContext.chatTriggerIconOpen && isOpen) {
      return "custom-open-icon";
    }
    if (themeContext.chatTriggerIconClose && !isOpen) {
      return "custom-closed-icon";
    }
  }

  const isInFrame = (theme) => {
    const isInFrameVal =
      window.location !== window.parent.location &&
      document.querySelector('meta[name="42q-iframed"]')?.content === "true";
    //    console.log(isInFrameVal);
    return isInFrameVal;
  };

  return (
    <ButtonTriggerStyle
      onClick={onClick}
      aria-label={isOpen ? "Close" : "Open"}
      $inFrame={isInFrame()}
      className={customTrigger()}
    >
      {isOpen ? (
        <>
          <Close />
        </>
      ) : (
        <>
          <Eyes />
        </>
      )}
    </ButtonTriggerStyle>
  );
}
