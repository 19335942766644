import styled from "@emotion/styled";

const CompletionSpinnerContainerStyle = styled.div`
  width: 100%;
  min-height: 124px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  margin: ${(props) => (!props.inner ? "10px 0" : "")};
`;

const ChatSpinnerStyle = styled.div`
  position: relative;
  top: ${(props) => (props.inner ? "-4px" : "4px")};
  display: inline-block;
  border-radius: 50%;
  -webkit-animation: o-loading-spinner 1s infinite linear;
  animation: o-loading-spinner 1s infinite linear;
  border-style: solid;
  border-color: rgba(51, 48, 46, 0.25);
  border-top-color: #33302e;
  width: 20px;
  height: 20px;
  border-width: 3px;
`;

export default function ChatSpinner(props) {
  const { inner } = props;
  return (
    <CompletionSpinnerContainerStyle inner={inner}>
      <ChatSpinnerStyle inner={inner} />
    </CompletionSpinnerContainerStyle>
  );
}
