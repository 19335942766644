import styled from "@emotion/styled";
import { DEMO_THEME, Link } from "../theme.js";
import { IconInfo, IconClose } from "../icons/icons.js";

const StyledPopoverContainer = styled.div`
  background-color: ${DEMO_THEME.white};
  border-bottom: 1px solid ${DEMO_THEME.border};
  padding: 22px 20px;
  display: flex;
  justify-contenr: center;
`;

const StyledPopover = styled.div`
  color: ${DEMO_THEME.grey_600};
  font-family: Public Sans;
  font-size: 12px;
  line-height: 1.5;
  display: inline-flex;
  flex-flow: row nowrap;
  margin: 0 auto;
  position: relative;
  padding-right: ${(setOpen) => setOpen && "43px"};
  .icon-info {
    margin-right: 4px;
    flex-shrink: 0;
  }
`;

const StyledClose = styled.button`
  cursor: pointer;
  padding: 0;
  border: 0;
  position: absolute;
  right: 0;
  top: -5px;
  width: 25px;
  height: 25px;
  background-color: transparent;
`;

export default function WidgetPopover(props) {
  const { mode, setOpen } = props;

  function changeMode(e) {
    e.preventDefault();
    const urlParams = new URLSearchParams(window.location.search);

    // ensure params are in
    if (![...urlParams.keys()].includes("chatType")) {
      urlParams.append("chatType", "overlay");
    }
    if (![...urlParams.keys()].includes("mode")) {
      urlParams.append("mode", "demo");
    }
    const newParamsArr = [...urlParams.entries()].map(([key, value]) => {
      if (key === "mode") return [key, value === "live" ? "demo" : "live"];
      return [key, value];
    });

    const urlParamsProc = new URLSearchParams(newParamsArr);

    // redirect
    window.location.href =
      window.location.href.split("?")[0] + "?" + urlParamsProc.toString();
  }

  return (
    <StyledPopoverContainer>
      <StyledPopover>
        <IconInfo width="16px" height="16px" />
        {mode === "demo" && (
          <span>
            Demo Mode enables the Widget settings to be previewed without
            needing an active Subscription and positive Credit Balance.{" "}
            <Link href="#" onClick={(e) => changeMode(e, "live")}>
              Change to Live Mode
            </Link>{" "}
            to preview the Widget as if installed.{" "}
            <Link href="https://kb.42q.ai/help/widget/what-is-the-difference-between-previewing-the-widget-in-demo-mode-and-live-mode/">
              Learn more
            </Link>
          </span>
        )}

        {mode === "live" && (
          <span>
            Live Mode enables the Widget to be previewed as if installed. An
            active Subscription and positive Credit Balance are required to
            preview the Widget without restrictions.{" "}
            <Link href="#" onClick={changeMode}>
              Change to Demo Mode
            </Link>{" "}
            to just preview the Widget settings.{" "}
            <Link href="https://kb.42q.ai/help/widget/what-is-the-difference-between-previewing-the-widget-in-demo-mode-and-live-mode/">
              Learn more
            </Link>
          </span>
        )}
        {setOpen && (
          <StyledClose aria-label="Close" onClick={setOpen}>
            <IconClose width="25px" height="25px" />
          </StyledClose>
        )}
      </StyledPopover>
    </StyledPopoverContainer>
  );
}
