import { useState } from "react";
import { throttle as _throttle } from "lodash";

// import useResizeObserver from "use-resize-observer";
import { ThemeProvider } from "@emotion/react";
import styled from "@emotion/styled";
// components
import {
  StyledPlaceholderSection,
  StyledPlaceholderContainer,
  StyledGroupedDivs,
  StyledPlaceholderFooter,
} from "./theme.js";

export default function AppDemo(props) {
  const { mode, chatType } = props;
  return (
    <StyledPlaceholderSection>
      <StyledPlaceholderContainer>
        <StyledGroupedDivs className={chatType != "inline" && "no-mt"}>
          <div />
          <div />
          <div />
          <div />
          <div />
        </StyledGroupedDivs>
        <StyledGroupedDivs>
          <div />
          <div />
          <div />
          <div />
          <div />
        </StyledGroupedDivs>
        <StyledGroupedDivs>
          <div />
          <div />
          <div />
          <div />
          <div />
        </StyledGroupedDivs>
        <StyledGroupedDivs>
          <div />
          <div />
          <div />
          <div />
          <div />
        </StyledGroupedDivs>
        <StyledGroupedDivs>
          <div />
          <div />
          <div />
          <div />
          <div />
        </StyledGroupedDivs>
        <StyledGroupedDivs>
          <div />
          <div />
          <div />
          <div />
          <div />
        </StyledGroupedDivs>
      </StyledPlaceholderContainer>

      <StyledPlaceholderFooter />
      {/* I am slotted below the inline widget (if it's there) {mode} {chatType} */}
    </StyledPlaceholderSection>
  );
}
