import styled from "@emotion/styled";
import Button from "../Button.js";
import { useDispatch } from "react-redux";
import { setIsOpen, addToQueue } from "../../features/widgetStateSlice.js";

export const FQListStyle = styled.ul`
  background-color: ${(props) => props.theme.windowBg};
  padding: 1.5px 10px 1.5px 20px;
  margin: -1px 0 0;
  border-radius: 0 0 4px 4px;
  li {
    font-family: ${(props) => props.theme.font};
    margin: 8px 0;
    list-style-type: none;
    font-size: 14px;
    line-height: 1.29;
    color: ${(props) => props.theme.systemMessageText};
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      margin-left: 10px;
      align-self: flex-start;
    }
  }
`;

export default function FQList(props) {
  const { questions } = props;
  const dispatch = useDispatch();

  return (
    <FQListStyle questions={questions}>
      {questions.questions.map(function (question, index) {
        return (
          <li key={question.id}>
            {question.title}{" "}
            <Button
              text={"Send"}
              size={"big"}
              href={question.content_url}
              component={Button}
              sendBtn={true}
              onClick={() => {
                window.open(question.content_url, "_blank").focus();
              }}
              // onClick={() => {
              //   dispatch(addToQueue({ name: "init_fq", text: question }));
              //   dispatch(setIsOpen(true));
              // }}
            />
          </li>
        );
      })}
    </FQListStyle>
  );
}
