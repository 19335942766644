import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { useContext } from "react";
import ChatHeader from "./chatwindow/ChatHeader.js";
import Badge from "./chatwindow/Badge.js";
import WelcomeMessage from "./chatwindow/messages/WelcomeMessage.js";
import { useDispatch } from "react-redux";
import { setIsOpen } from "../features/widgetStateSlice.js";

export const ChatTriggerInlineStyle = styled.div`
  background: ${(props) => props.theme.windowBg};
  ${(props) =>
    props.theme.chatType === "inline" &&
    `
      @media (min-width: 491px) {
        display: none;
        background: red;
      }
      
    `}
  .chat-header-close {
    display: none;
  }
`;

const ChatTriggerInlineBodyStyle = styled.div`
  padding: 17px 20px 10px 20px;
  font-family: ${(props) => props.theme.font};
  font-size: 14px;
  line-height: 1.29;
  color: ${(props) => props.theme.systemMessageText};
  .chat-bot-icon {
    display: none;
  }
  .chat-bubble {
    width: 100%;
    padding: 0;
    background: transparent;
  }
`;

export default function ChatTriggerInline(props) {
  const { isOpen, handleClick, questions, showQuestions } = props;
  const themeContext = useTheme();

  return (
    <ChatTriggerInlineStyle $isOpen={isOpen}>
      {themeContext.visibilityWidgetWindowHeader && (
        <ChatHeader
          withButton={
            themeContext.buttonText &&
            themeContext.handoverButtonLinkHref &&
            themeContext.visibilityHandoverButton
          }
        />
      )}
      <ChatTriggerInlineBodyStyle>
        <WelcomeMessage
          variant={"search"}
          questions={questions}
          showQuestions={showQuestions}
          inlineTriggerHandler={handleClick}
        ></WelcomeMessage>
      </ChatTriggerInlineBodyStyle>
      <Badge />
    </ChatTriggerInlineStyle>
  );
}
