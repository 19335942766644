export default function Close() {
  return (
    <svg
      width="25.916129px"
      height="25.916129px"
      viewBox="0 0 25.916129 25.916129"
      id="close-svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-1332.000000, -916.000000)"
          fill="#FFFFFF"
          fillRule="nonzero"
        >
          <g transform="translate(934.000000, 213.000000)">
            <g transform="translate(366.000000, 671.000000)">
              <g transform="translate(32.000000, 32.000000)">
                <polygon points="22.6766129 25.916129 25.916129 22.6766129 16.1975806 12.9580645 25.916129 3.23951613 22.6766129 -1.07765648e-14 12.9580645 9.71854839 3.23951613 -1.07765648e-14 4.00272408e-14 3.23951613 9.71854839 12.9580645 4.00272408e-14 22.6766129 3.23951613 25.916129 12.9580645 16.1975806"></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
