import styled from "@emotion/styled";
import { SystemMessageStyle } from "./MessageStyles.js";
import BotFace from "../../icons/BotFace.js";

// ----------------------------------------------------------------------

const TestMessageStyle = styled(SystemMessageStyle)`
  min-width: 100px;
  min-height: 50px;
  background-color: blue;
  color: white;
`;

export default function TestMessage(props) {
  const { children } = props;
  return (
    <TestMessageStyle>
      <BotFace />
      <div className="chat-bubble">{children}</div>
    </TestMessageStyle>
  );
}
