import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  createStateSyncMiddleware,
  // initMessageListener,
  initStateWithPrevTab,
  withReduxStateSync,
} from "redux-state-sync";
import widgetStateReducer, {
  initialState,
} from "../features/widgetStateSlice.js";

const appReducer = combineReducers({
  widgetState: widgetStateReducer,
});

const makeStore = (channelName, GLOB_DEBUG) => {
  const config = { channel: channelName };

  if (GLOB_DEBUG) console.log(`Setting up store with channel ${channelName}`);

  const store = configureStore({
    preloadedState: {
      widgetState: {
        ...initialState,
        channelName,
      },
    },
    reducer: withReduxStateSync(appReducer),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(createStateSyncMiddleware(config)),
  });

  initStateWithPrevTab(store);
  // initMessageListener(store); // not needed any more
  return store;
};

export default makeStore;
