import parse from "html-react-parser";
import styled from "@emotion/styled";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { SystemMessageStyle } from "./MessageStyles.js";
import BotFace from "../../icons/BotFace.js";
import { EVENT_REINIT_SILOS } from "../../../utils/controller.js";
import { addToQueue } from "../../../features/widgetStateSlice.js";
// ----------------------------------------------------------------------

const SystemResponseMessageStyle = styled(SystemMessageStyle)``;

export default function SystemResponseMessage(props) {
  const {
    text,
    textHTML,
    textHTMLNoAgents,
    textElements,
    preset,
    isRelevant,
    agentsAvailable,
  } = props;
  const dispatch = useDispatch();

  const getText = useMemo(() => {
    // console.log(textHTML);
    return (
      <>
        {!textHTML && <>{text}</>}
        {textHTML && (
          <>
            {parse(textHTML, {
              replace(domNode) {
                // console.log(domNode);
                if (
                  domNode.attribs &&
                  domNode.attribs.class === "link_flowreset"
                ) {
                  return (
                    <a
                      disabled={!isRelevant}
                      style={{
                        pointerEvents: isRelevant ? "" : "none",
                        opacity: isRelevant ? "" : "0.6",
                      }}
                      onClick={() => {
                        if (isRelevant)
                          dispatch(addToQueue({ name: EVENT_REINIT_SILOS }));
                      }}
                    >
                      start again
                    </a>
                  );
                } else if (
                  domNode.attribs &&
                  domNode.attribs.href === "https://www.42q.ai/show-42q-chat"
                ) {
                  // console.log("42Q magic chat link FOUND");
                  // console.log(domNode);
                  return (
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        window.parent.postMessage(
                          {
                            channelName,
                            name: "open42QChat",
                            mode,
                          },
                          "*"
                        );
                      }}
                    >
                      {domNode.children[0].data}
                    </a>
                  );
                }
              },
            })}
          </>
        )}
      </>
    );
    // }
  }, [textHTML]);

  return (
    <SystemResponseMessageStyle>
      <BotFace />
      <div className="chat-bubble">
        <>{getText}</>
        {/* {preset ? (
          <>
            {preset === "group1" && (
              <>
                {getText()} or{" "}
                <a
                  disabled={!isRelevant}
                  style={{
                    pointerEvents: isRelevant ? "" : "none",
                    opacity: isRelevant ? "" : "0.6",
                  }}
                  onClick={() => {
                    if (isRelevant)
                      dispatch(addToQueue({ name: EVENT_REINIT_SILOS }));
                  }}
                >
                  start again
                </a>
                .
              </>
            )}
          </>
        ) : (
          <>{getText()}</>
        )} */}
      </div>
    </SystemResponseMessageStyle>
  );
}
