import parse from "html-react-parser";
import { USER_SETTINGS_DEFAULTS } from "./widget.js";

export const MESSAGES = [
  { type: "WelcomeMessage", variant: "search" },
  {
    type: "UserResponseMessage",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eget ante felis.",
  },
  {
    type: "SearchResultsMessage",
    articles: [
      { metadata: { article_path: "test" }, title: "Lorem ipsum dolor sit?" },
      { metadata: { article_path: "test" }, title: "Mauris a risus sapien?" },
      {
        metadata: { article_path: "test" },
        title: "Aenean et lacus varius leo consequat?",
      },
    ],
  },
  // {
  //   type: "CompletionMessage",
  //   isFinished: false,
  //   noArticlesFound: 2,
  // },
  {
    type: "CompletionMessage",
    isFinished: true,
    noArticlesFound: 2,
    answer: parse(
      "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel nisl nulla. Fusce semper, turpis et convallis vehicula, diam augue tincidunt libero, at efficitur justo ex sit amet quam.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel nisl nulla. Fusce semper, turpis et convallis vehicula, diam augue tincidunt libero, at efficitur justo ex sit amet quam.</p>"
    ),
    sourceQuestion: {
      metadata: { article_path: "test" },
      title: "Lorem ipsum dolor sit amet?",
    },
    agentsAvailable: true,
  },
  {
    type: "CompletionMessage",
    isFinished: true,
    agentsAvailable: true,
  },
  {
    type: "SystemResponseMessage",
    text: "Lorem ipsum",
  },
  {
    type: "SystemResponseMessage",
    textHTML: "<b>Lorem ipsum</b>",
  },
  {
    type: "BinaryResponseMessage",
  },
  {
    type: "BinaryResponseMessage",
  },
];

const MOCK_SILOS = [
  [1, "Group Subscriptions"],
  [2, "Republishing"],
  [3, "Partner Programme"],
  [4, "Integrated Solutions"],
];

const MOCK_QUESTIONS = [
  "How can I subscribe?",
  "Can I republish your articles?",
  "Do you offer student discounts?",
];

const MOCK_CONFIGS = {
  overlayNoSilosSearch: {
    ...USER_SETTINGS_DEFAULTS,
    chatType: "overlay",
    silos: [],
    chatMode: "search",
    featuredQuestions: MOCK_QUESTIONS,
  },
  overlaySilosSearch: {
    ...USER_SETTINGS_DEFAULTS,
    chatType: "overlay",
    silos: MOCK_SILOS,
    chatMode: "search",
    featuredQuestions: MOCK_QUESTIONS,
  },

  overlayNoSilosAnswers: {
    ...USER_SETTINGS_DEFAULTS,
    chatType: "overlay",
    silos: [],
    chatMode: "full",
    featuredQuestions: MOCK_QUESTIONS,
  },
  overlaySilosAnswers: {
    ...USER_SETTINGS_DEFAULTS,
    chatType: "overlay",
    silos: MOCK_SILOS,
    chatMode: "full",
    featuredQuestions: MOCK_QUESTIONS,
  },

  inlineNoSilos: {
    ...USER_SETTINGS_DEFAULTS,
    chatType: "inline",
    silos: [],
    featuredQuestions: MOCK_QUESTIONS,
  },
  inlineSilos: {
    ...USER_SETTINGS_DEFAULTS,
    chatType: "inline",
    silos: MOCK_SILOS,
    featuredQuestions: MOCK_QUESTIONS,
  },
  inlineNoSilosAnswers: {
    ...USER_SETTINGS_DEFAULTS,
    chatType: "inline",
    silos: [],
    chatMode: "full",
    featuredQuestions: MOCK_QUESTIONS,
  },
  inlineSilosAnswers: {
    ...USER_SETTINGS_DEFAULTS,
    chatType: "inline",
    silos: MOCK_SILOS,
    chatMode: "full",
    featuredQuestions: MOCK_QUESTIONS,
  },
};

export const fourtyTwoQSettingsService = {
  // Mock service
  fetchConfig: (appId) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const retConfig = { ...MOCK_CONFIGS[appId.split("-")[1]], appId };
        // console.log("Generated mock config", retConfig);
        resolve(retConfig, 300);
      }, 700);
    });
  },
};

const demoModeURL =
  "https://kb.42q.ai/help/widget/what-is-the-difference-between-previewing-the-widget-in-demo-mode-and-live-mode";

export const fourtyTwoQService = {
  // Mock service
  search: (appId, input) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: {
            type: "search_results",
            results: [
              {
                chunk_id: 69,
                content_id: 69,
                content_title: "You are in Demo Mode",
                content_url: demoModeURL,

                chunk_text: "",
                similarity: 0.69,
              },
              {
                chunk_id: 69,
                content_id: 69,
                content_title:
                  "It allows you to preview the Widget settings without needing an active Subscription and positive Credit Balance",
                content_url: demoModeURL,
                chunk_text: "",
                similarity: 0.69,
              },
              {
                chunk_id: 69,
                content_id: 69,
                content_title:
                  "Find out more about the difference between Demo Mode and Live Mode",
                content_url: demoModeURL,
                chunk_text: "",
                similarity: 0.69,
              },
            ],
          },
        });
      }, 700);
    });
  },
  answers: (appId, input) => {
    return new Promise((resolve, reject) => {
      const sampleAnswerText =
        "This is a sample answer because you are in Demo Mode, which allows you to preview the Widget settings without needing an active Subscription and positive Credit Balance.";
      setTimeout(() => {
        resolve({
          data: {
            app_id: appId,
            type: "completion_answer",
            answer: {
              answer: sampleAnswerText,
              qids: ["69"],
              relevant: true,
            },
            answer_html: `<p>${sampleAnswerText}</p>`,
            content: {
              title:
                "What is the difference between previewing the Widget in Demo Mode and Live Mode?",
              url: demoModeURL,
            },
          },
        });
      }, 4000);
    });
  },
};
