import HTTP from "../http.js";
import { get as _get } from "lodash";

export const ERROR_INVALID_JOB = "Invalid response, job not submitted";

function search(
  appId,
  query,
  silo,
  privateEndpoints,
  externalHTTP,
  GLOB_DEBUG
) {
  const deploymentType = privateEndpoints === true ? "private" : "anon";
  const curHTTP = externalHTTP ? externalHTTP : HTTP;
  return curHTTP.get(
    `api/v1/${deploymentType}/widget/${appId}/search/?input=${encodeURIComponent(
      query
    )}&silo=${silo ? encodeURIComponent(silo) : 0}`
  );
}

function answers(
  appId,
  query,
  silo,
  privateEndpoints,
  externalHTTP,
  GLOB_DEBUG
) {
  const deploymentType = privateEndpoints === true ? "private" : "anon";
  const curHTTP = externalHTTP ? externalHTTP : HTTP;

  // this needs to be a manual Promise
  return new Promise((resolve, reject) => {
    curHTTP
      .post(
        `api/v1/${deploymentType}/widget/${appId}/answers/?input=${encodeURIComponent(
          query
        )}&silo=${silo ? encodeURIComponent(silo) : 0}`,
        {}
      )
      .then((response) => {
        // we jave job id
        if (GLOB_DEBUG) console.log("Job response:", response);
        if (response.data.status != "SUBMITTED") {
          if (response.data.type === "moderation_flagged") {
            resolve({
              ...response,
              data: response.data,
            });
          } else if (response.data.type === "search_results") {
            // returned when no content is found
            resolve({
              ...response,
              data: response.data,
            });
          } else {
            reject(ERROR_INVALID_JOB);
          }
          return;
        }
        const job_id = response.data.job_id;

        // now check
        const max_time_check = 60;
        const check_wait = 3 * 1000;
        const job_check_url =
          `api/v1/${deploymentType}/widget/${appId}/answers/jobcheck/?jobid=` +
          job_id;

        let times_checked = 0;
        const check_fun = () => {
          curHTTP
            .get(job_check_url)
            .then((response) => {
              if (GLOB_DEBUG) console.log("Check response:", response.data);
              if (response.data.status == "SUCCESS") {
                resolve({
                  ...response,
                  data: response.data.res,
                });
              } else if (response.data.status == "ERROR") {
                reject(response);
              } else {
                times_checked += 1;
                if (times_checked < max_time_check) {
                  setTimeout(check_fun, check_wait);
                } else {
                  reject("TIMEOUT, giving up checking");
                }
              }
            })
            .catch((error) => {
              // reject on error
              reject(error);
            });
        };
        check_fun();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function settings(appId, GLOB_DEBUG) {
  return new Promise((resolve, reject) => {
    HTTP.get(`api/v1/anon/widget/${appId}/settings/`)
      .then((response) => {
        if (GLOB_DEBUG) console.log("Config received:", response.data);
        const deploymentSettings = response.data.deployment;
        // process config and map
        // TODO - how will we encode chat type? Should be in the URL
        const dataMapped = {
          // fixed params
          appId: appId,
          chatInlineHeaderXL: true,
          // chatType: "overlay",
          inverseThreshold: 45,
          maxLinkLightness: 35,
          maxBackgroundLightness: 95,
          binaryMessageText: "Did that answer your question?",

          // server-driven params
          chatInlineHeight: deploymentSettings.sizing_widget_window_height,
          background: deploymentSettings.background_colour,
          highlight: deploymentSettings.highlight_colour,
          chatAlignment: deploymentSettings.positioning_widget_alignment,
          chatTriggerIconClose: deploymentSettings.trigger_closed_path_url,
          chatTriggerIconOpen: deploymentSettings.trigger_open_path_url,

          chatOuterSpacing: deploymentSettings.spacing_widget_padding,
          logo: deploymentSettings.logo_path_url,
          buttonText: deploymentSettings.handover_button_label,
          FQtitle: deploymentSettings.featured_content_label,
          showQuestions:
            deploymentSettings.featured_content_enabled &&
            deploymentSettings.featured_content.length > 0,

          headerWithButton: deploymentSettings.handover_button_action,
          chatMode: deploymentSettings.project_subtype,
          silos:
            deploymentSettings.content_siloing_enabled &&
            deploymentSettings.content_silos.length > 0
              ? deploymentSettings.content_silos.map((silo) => [
                  silo.id,
                  silo.name,
                ])
              : [],
          welcomeText: deploymentSettings.messages_welcome_html,
          welcomeTextSilos:
            deploymentSettings.messages_content_silos_filter_html,
          welcomeTextSilosIntro:
            deploymentSettings.messages_content_silos_intro_html,

          noContentIdentifiedWithSilos:
            deploymentSettings.messages_no_content_identified_with_silos_html,
          noContentIdentifiedNoSilos:
            deploymentSettings.messages_no_content_identified_no_silos_html,

          positiveFeedbackWithSilos:
            deploymentSettings.messages_positive_feedback_with_silos_html,

          positiveFeedbackNoSilos:
            deploymentSettings.messages_positive_feedback_no_silos_html,

          negativeFeedbackWithSilos:
            deploymentSettings.messages_negative_feedback_with_silos_html,

          negativeFeedbackNoSilos:
            deploymentSettings.messages_negative_feedback_no_silos_html,

          noContentIdentifiedWithSilosNotSelected:
            deploymentSettings.messages_no_content_identified_with_silos_notselected_html,
          positiveFeedbackWithSilosNotSelected:
            deploymentSettings.messages_positive_feedback_with_silos_notselected_html,
          negativeFeedbackWithSilosNotSelected:
            deploymentSettings.messages_negative_feedback_with_silos_notselected_html,

          featuredQuestions: deploymentSettings.featured_content_enabled
            ? deploymentSettings.featured_content
            : [],

          handoverButtonLinkHref: deploymentSettings.handover_button_linkhref,
          handoverButtonTarget: deploymentSettings.handover_button_target,

          handoverLinkLabel: deploymentSettings.handover_link_label,
          handoverLinkLinkHref: deploymentSettings.handover_link_linkhref,

          askAQuestionLabel: deploymentSettings.inline_trigger_button_label,

          active: deploymentSettings.active,
          activeCreditSubscription:
            deploymentSettings.active_credit_subscription,

          visibilityWidgetWindow: deploymentSettings.visibility_widget_window,
          visibilityWidgetWindowHeader:
            deploymentSettings.visibility_widget_window_header,
          visibilityHandoverButton:
            deploymentSettings.visibility_handover_button,
        };
        if (GLOB_DEBUG) console.log(dataMapped);

        // reject("TEST");
        resolve(dataMapped);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function widget_demo_available(appId) {
  return HTTP.get(`api/v1/demo/widget/${appId}/availability/`);
}

export default {
  search,
  answers,
  settings,
  widget_demo_available,
};
