import styled from "@emotion/styled";

import { ALL_FRAMES } from "../features/widgetStateSlice.js";
import { useSelector } from "react-redux";
import { ChatWindowStyle } from "./chatwindow/ChatWindow.js";
import { FQWindowStyles } from "./FQ.js";
import { ChatTriggerInlineStyle } from "./ChatTriggerInline.js";

const HiddenDiv = styled.div`
  display: none;
`;

const FixedDiv = styled.div`
  position: fixed;
  left: 10px;
  bottom: 10px;
  border: 1px solid black;
  padding: 10px;
  padding-bottom: 0px;
  background-color: rgba(255, 255, 255, 0.2);
`;

export default function ParentStylesShim({ frames, theme }) {
  const widgetState = useSelector((state) => state.widgetState);
  const isOpen = useSelector((state) => state.widgetState.isOpen);
  const isFeaturesOpen = useSelector(
    (state) => state.widgetState.isFeaturesOpen
  );

  return (
    <div>
      <HiddenDiv>
        <h3 style={{ marginTop: "0px" }}>Debug info</h3>
        <h4>UI State (pushed from iframes):</h4>
        <ul>
          <li>isOpen: {isOpen ? "open" : "closed"}</li>
          <li>isFeaturesOpen: {isFeaturesOpen ? "open" : "closed"}</li>
        </ul>
        <h4>Frames loaded:</h4>
        <ul>
          {ALL_FRAMES.map((el) => {
            return (
              <li key={el}>
                {el}: {widgetState.loadedFrames[el] ? "loaded" : "pending"}
              </li>
            );
          })}
        </ul>
      </HiddenDiv>
      <HiddenDiv>
        <ChatWindowStyle
          className={frames.messages?.id}
          $isOpen={isOpen}
        ></ChatWindowStyle>

        {theme.chatType === "overlay" && (
          <FQWindowStyles
            aria-modal="true"
            $isFeaturesOpen={isFeaturesOpen}
            className={frames.fqs?.id}
          >
            FQ window parent
          </FQWindowStyles>
        )}

        {theme.chatType === "inline" && (
          <ChatTriggerInlineStyle
            aria-modal="true"
            $isFeaturesOpen={isFeaturesOpen}
            className={frames.imt?.id}
          >
            Chat trigger inline parent
          </ChatTriggerInlineStyle>
        )}
      </HiddenDiv>
    </div>
  );
}
