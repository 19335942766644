import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import { setIsFeaturesOpen } from "../../features/widgetStateSlice.js";

import Close from "../icons/Close.js";

export const FQHeaderStyle = styled.div`
  position: relative;
  top: -1px;
  padding: 0 20px;
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.headerText};
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 51px;
  box-sizing: border-box;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  p {
    font-family: ${(props) => props.theme.font};
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.13;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 205px;
  }
`;

export const FQHeaderCloseStyle = styled.button`
  width: 16px;
  height: 16px;
  margin-left: 20px;
  margin-right: -2px;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  svg {
    width: 14px;
    position: relative;
    top: -5px;
    g {
      fill: ${(props) => props.theme.headerText};
    }
  }
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: ${(props) => props.theme.focus};
  }
`;

export default function FQTitle(props) {
  const { title } = props;
  const dispatch = useDispatch();

  return (
    <FQHeaderStyle title={title}>
      <p>{title}</p>
      <FQHeaderCloseStyle
        aria-label="Close"
        onClick={() => dispatch(setIsFeaturesOpen(false))}
      >
        <Close />
      </FQHeaderCloseStyle>
    </FQHeaderStyle>
  );
}
