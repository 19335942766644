import styled from "@emotion/styled";

const DotLoaderStyle = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  & > div {
    background-color: ${(props) => props.theme.dotsColor};
    display: inline-block;
    ${(props) =>
      props.size === "small"
        ? `
          animation: dotSmall cubic-bezier(0.25, 0.1, 0.25, 1) 1.5s infinite;
          height: 4px;
          width: 4px;
          border-radius: 1px;
        `
        : `
          animation: dotLarge cubic-bezier(0.25, 0.1, 0.25, 1) 1.5s infinite;
          height: 20px;
          width: 20px;
          border-radius: 4px;
        `}
  }

  & > div:nth-of-type(2) {
    animation-delay: 0.2s;
    ${(props) =>
      props.size === "small"
        ? `
          margin: 0 5px;
        `
        : `
          margin: 0 10px;
        `}
  }

  & > div:nth-of-type(3) {
    animation-delay: 0.3s;
  }

  @keyframes dotSmall {
    30% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px);
    }
    60% {
      transform: translateY(2px);
    }
    70% {
      transform: translateY(0);
    }
    80% {
      transform: translateY(2px);
    }
    90% {
      transform: translateY(0);
    }
  }
  @keyframes dotLarge {
    30% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(5px);
    }
    70% {
      transform: translateY(0);
    }
    80% {
      transform: translateY(5px);
    }
    90% {
      transform: translateY(0);
    }
  }
`;

export default function DotLoader(props) {
  const { size } = props;
  return (
    <DotLoaderStyle size={size}>
      <div></div>
      <div></div>
      <div></div>
    </DotLoaderStyle>
  );
}
