import styled from "@emotion/styled";
import { useState, useEffect } from "react";

import ChatMessages from "./ChatMessages.js";
import ChatInput from "./ChatInput.js";
import parse from "html-react-parser";

// ----------------------------------------------------------------------

// lifted props examples

// IMPORTANT - THE BELOW NEEDS TO DYNAMICALLY SHRINK VERTICALLY
// depending on the height of the messages, atm we are using fixed offset and
// that will not work going forward
export const ChatWindowStyle = styled.div`
  position: fixed;
  bottom: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
  -webkit-box-shadow: ${(props) => props.theme.boxShadow};
  box-shadow: ${(props) => props.theme.boxShadow};
  box-sizing: border-box;
  min-height: 308px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.windowBg};
  transition: 0.1s opacity ease;
  opacity: ${(props) => (props.$isOpen ? "1" : "0")};
  visibility: ${(props) => (props.$isOpen ? "visible" : "hidden")};
  /* INLINE STYLES */
  ${(props) =>
    props.theme.chatType === "inline" &&
    `
     @media (max-width: 490px) {
      left: 0px;
      height: 100%;
     }
     @media (min-width: 491px) {
        position: static;
        height: ${props.theme.chatInlineHeight}px;
        opacity: 1;
        visibility: visible;
     }
     ${
       !props.$isOpen &&
       `
          ${` @media (max-width: 490px) {
          height: 0;
          min-height: 0
        }`}`
     }  
    `}
  /* OVERLAY STYLES */
  ${(props) =>
    props.theme.chatType === "overlay" &&
    `
    @media (max-width: 490px) {
      height: 100%;
      left: 0;
    }
    @media (min-width: 491px) {
      width: 400px;
      border-radius: 4px;
      width: 400px;
      height: 654px;
      max-height: calc(100vh - 160px);
      top: auto;
      bottom: ${props.theme.chatOuterSpacing + 70}px;
      right: ${props.theme.chatOuterSpacing}px;
      ${
        props.theme.chatAlignment === "left" &&
        `
          left: ${props.theme.chatOuterSpacing}px;
      `
      };
    }
  `};
`;

const ToggleAgentsStyle = styled.div`
  left: 20px;
  bottom: 20px;
  z-index: 9999;
  width: 300px;
  position: fixed;
`;

export const TEST_MESSAGE = { type: "TestMessage", text: "hello 0" };

const WELCOME_MESSAGE = { type: "WelcomeMessage", variant: "search" };

export default function ChatWindow(props) {
  const { isOpen, gVariant } = props;

  const [controllerMode, setControllerMode] = useState("search");

  const [groupFilterMode, setGroupFilterMode] = useState(false);

  const [messages, setMessages] = useState([WELCOME_MESSAGE]);

  const [isProcessing, setIsProcesssing] = useState(false);
  const [interimSpinner, setInterimSpinner] = useState(false);

  useEffect(() => {
    // on page load, initialise as group if passed on as parameter
    if (gVariant === "group") {
      switchToGroupFilterEnabled();
    }
  }, []);

  const restartInteraction = () => {
    setIsProcesssing(false);
    setMessages([]);
  };

  const switchToChat = (e) => {
    setControllerMode("chat");
    restartInteraction();
    setMessages([{ ...WELCOME_MESSAGE, variant: "chat" }]);
    if (groupFilterMode) {
      switchToGroupFilterEnabled();
    }
  };
  const switchToSearch = (e) => {
    setControllerMode("search");
    restartInteraction();
    setMessages([WELCOME_MESSAGE]);
    if (groupFilterMode) {
      switchToGroupFilterEnabled();
    }
  };

  const switchToGroupFilterEnabled = (e) => {
    setGroupFilterMode(true);
    setGroupSelected(null);
    restartInteraction();
    setIsProcesssing(true);
    setMessages([{ ...WELCOME_MESSAGE, variant: "group" }]);
  };
  const switchToGroupFilterDisabled = (e) => {
    setGroupFilterMode(false);
    setGroupSelected(null);
    restartInteraction();
    setMessages([WELCOME_MESSAGE]);
  };

  const questionEntryHandler = (query) => {};

  return (
    <>
      <ChatWindowStyle $isOpen={isOpen}>
        <ChatMessages messages={messages} interimSpinner={interimSpinner} />
        <ChatInput
          testClickHandler={questionEntryHandler}
          isProcessing={isProcessing}
        />
      </ChatWindowStyle>

      {/* <ToggleAgentsStyle>
        {controllerMode === "search" && (
          <>
            <button disabled={isProcessing} onClick={switchToChat}>
              Switch to full
            </button>{" "}
            Mode: {controllerMode}
          </>
        )}
        {controllerMode === "chat" && (
          <>
            <button disabled={isProcessing} onClick={switchToSearch}>
              Switch to search
            </button>{" "}
            Mode: full
          </>
        )}
        <br />
        {(!gVariant || gVariant.length == 0) && (
          <>
            {groupFilterMode && (
              <>
                <button onClick={switchToGroupFilterDisabled}>
                  Switch to non-grouped
                </button>{" "}
                Mode: grouped
              </>
            )}
            {!groupFilterMode && (
              <>
                <button onClick={switchToGroupFilterEnabled}>
                  Switch to grouped
                </button>{" "}
                Mode: non-grouped
              </>
            )}
          </>
        )}
      </ToggleAgentsStyle> */}
    </>
  );
}
