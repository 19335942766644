import styled from "@emotion/styled";
import { useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../hook-form/FormProvider.js";
import autosize from "autosize";
import Button from "../Button.js";

const ChatInputStyle = styled.div`
  border-top: 1px solid ${(props) => props.theme.inputBorder};
  box-sizing: border-box;
  pointer-events: ${(props) => (props.$isProcessing ? "none" : "")};
  form {
    max-width: 1180px;
    width: 100%;
    margin: 0 auto;
  }
  textarea {
    border: none;
    overflow-y: auto;
    overflow-x: hidden !important;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
    margin: 22px 20px 22px 0;
    max-height: 201px;
    width: calc(100% - 20px);
    font-family: ${(props) => props.theme.font};
    box-sizing: border-box;
    padding: 0;
    background-color: ${(props) => props.theme.windowBg};
    color: ${(props) => props.theme.inputText};
    font-size: 16px;
    line-height: 1.29;
    &::placeholder {
      color: ${(props) => props.theme.inputPlaceholder};
    }
    ${
      "" /* &:focus-visible ~ .focus-mask {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      outline: ${(props) => props.theme.focus};
      outline-offset: -2px;
    } */
    }
  }
`;

const ChatInputRowStyle = styled.div`
  display: flex;
  justify-contet: space-between;
  padding: 0 20px;
  position: relative;
  & > button {
    align-self: flex-start;
    margin-top: 16px;
  }
`;

export default function ChatInput(props) {
  const { handleClick, isProcessing, debug } = props;
  const ref = useRef(null);

  //const [value, setValue] = useState("");

  const QuestionSchema = Yup.object().shape({
    question: Yup.string().required("Please enter a question"),
  });

  const defaultValues = {
    question: "",
  };

  const methods = useForm({
    resolver: yupResolver(QuestionSchema),
    defaultValues,
  });

  const {
    control,
    reset,
    // setError,
    // setValue,
    getValues,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = (data) => {
    if (debug) console.log(data);
    ref.current?.blur(); // remove focus from the text field
    reset();
    autosize.destroy(ref.current);
    handleClick(data.question);
  };

  useEffect(() => {
    autosize(ref.current);
  });

  const checkKeyDown = (e) => {
    if (e.key === "Enter") {
      const val = getValues().question;
      if (/\S/.test(val)) {
        methods.handleSubmit(onSubmit)();
      } else {
        e.preventDefault();
      }
    }
  };

  return (
    <ChatInputStyle $isProcessing={isProcessing}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <ChatInputRowStyle>
          <Controller
            name="question"
            control={control}
            defaultValue={defaultValues.question}
            render={({ field: { onChange, value } }) => (
              <>
                <textarea
                  onKeyDown={(e) => checkKeyDown(e)}
                  rows="1"
                  type="text"
                  ref={ref}
                  name="question"
                  value={value}
                  placeholder="Enter your question"
                  onChange={onChange}
                  disabled={isProcessing}
                ></textarea>
                <div className="focus-mask"></div>
                <Button
                  disabled={
                    isProcessing || errors["question"] || value.length === 0
                  }
                  text={"Send"}
                  size={"big"}
                  sendBtn={true}
                  onClick={handleSubmit(onSubmit)}
                />
              </>
            )}
          />
        </ChatInputRowStyle>
      </FormProvider>
    </ChatInputStyle>
  );
}
