import { lighten, darken } from "polished";
import { zip as _zip } from "lodash";
import { setFrameLoaded } from "../features/widgetStateSlice.js";
import { is_debug } from "./debug.js";

export const MASTER_MODE = "messages"; // which frame will push updates to the parent

export const uuidv4 = function () {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};
export const selectFramePosition = (state, mode) => {
  return state.widgetState.framePositions[mode];
};
export const selectFramePositions = (state) => {
  return state.widgetState.framePositions;
};

export const selectIsOpen = (state) => {
  return state.widgetState.isOpen;
};

export const selectFrameLoaded = (state, mode) => {
  return state.widgetState.loadedFrames[mode] === true;
};

export const selectFrameSize = (state, mode) => {
  return state.widgetState.frameSizes[mode];
};

export const selectIsFeaturesOpen = (state) => {
  return state.widgetState.isFeaturesOpen;
};

export const selectLoadedFrames = (state) => {
  return state.widgetState.loadedFrames;
};
export const injectIframeHTML = (
  mode,
  channelName,
  chatType,
  scriptSrc,
  idSuffix,
  parent,
  chatMode,
  widgetSrc,
  GLOB_DEBUG
) => {
  const iframe = document.createElement("iframe");
  iframe.style = "display: none;";

  // console.log("FRAMED SRC", document.currentScript.src);
  // simulate cross-origin
  // TODO - this needs to be separate for dev
  if (is_debug()) console.log("TESTING", mode, channelName, scriptSrc);
  const scriptUrl = new URL(scriptSrc);

  iframe.src = `${scriptUrl.protocol}//${
    scriptUrl.host
  }/iframe-component.html?mode=${encodeURIComponent(
    mode
  )}&channelName=${encodeURIComponent(
    channelName
  )}&chatType=${encodeURIComponent(chatType)}&chatMode=${encodeURIComponent(
    chatMode
  )}&debug_42qwidget=${GLOB_DEBUG ? "true" : "false"}`;

  if (parent) {
    parent.appendChild(iframe);
  } else {
    document.body.appendChild(iframe);
  }

  iframe.id = `iframe_${mode}_${idSuffix}`;
  return iframe;

  const iframeHTML = `
      <!DOCTYPE html>
      <head>        
        <title>42q frame</title>
        <meta name="42q-iframed" content="true">
        <meta name="42q-mode" content="${mode}">
        <meta name="42q-channel" content=${channelName}>
        <style>
          html, body {
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            overflow: hidden;
          }
          #root {
            width: 100%;
            height: 100%;
          }
        </style>
      </head>
        <body><div id="root"></div>
          <script src="${scriptSrc}"></script>
        </body>
      </html>`;

  iframe.contentWindow.document.open();
  iframe.contentWindow.document.write(iframeHTML);
  iframe.contentWindow.document.close();

  return iframe;
};

export const USER_SETTINGS_DEFAULTS = {
  // below will be populated based on user settings
  // it should not be used directly, it should always be combined
  // with baseTheme using the buildTheme method to create
  // final theme to be passed to components
  chatType: "overlay",
  chatInlineHeight: 500,
  chatInlineHeaderXL: true,
  background: "#212B36",
  highlight: "#00AB55",
  chatAlignment: "right",
  chatTriggerIconClose: ``,
  chatTriggerIconOpen: ``,
  chatOuterSpacing: 20,
  logo: ``,
  buttonText: "Chat with a human",
  FQtitle: "Featured Questions",
  showQuestions: true,
  inverseThreshold: 45,
  maxLinkLightness: 35,
  maxBackgroundLightness: 95,
  headerWithButton: true,
  chatMode: "search",
  silos: [],
  welcomeText: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
  lobortis ante vitae tincidunt gravida. Suspendisse vel lectus eu
  urna pulvinar viverra sed tristique magna.`,
  welcomeTextSilos: `So I can help you most effectively, are you interested in a
  specific service?`,
  binaryMessageText: "Did that answer your question?",
  contactLink: "https://widgetTest.42q.ai/contact/",
  featuredQuestions: [
    "Lorem ipsum dolor sit?",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit vestibulum commodo?",
    "Lorem ipsum dolor sit amet?",
  ],
};

export const THEME_42Q = {
  name: "THEME_42Q",
  // add here any other theme variables that will not be user-editable
  systemMessageBg: "#f5f5f5",
  systemMessageText: "#292929",
  font: "'Roboto', sans-serif",
  inputBorder: "#e3e3e3",
  inputText: "#28353d",
  inputPlaceholder: "#767676",
  focus: "2px solid #807973",
  boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.2)",
  windowBg: "#fff",
  windowInlineBg: "#f9f9f9",
  badgeBg: "#f1f1f1",
  badgeText: "#7c868c",
  badgeHoverText: "#565d62",
  dotsColor: "#767676",
  btnDisabledBg: "#c8c8c8",
  // these will have to change depending on user selected color
  regularTheme: {
    btnText: "#fff",
    // btnHoverBg: "rgba(91,164,43, 0.9)",
    // headerText: "#fff",
    userMessageText: "#fff",
  },
  inverseTheme: {
    btnText: "#000",
    // btnHoverBg: "rgba(91,164,43, 0.5)",
    // headerText: "#000",
    userMessageText: "#000",
  },
};

export const THEME_42Q_TEST = {
  name: "THEME_42Q_TEST",
  // add here any other theme variables that will not be user-editable
  systemMessageBg: "#f5f5f5",
  systemMessageText: "#292929",
  font: "'Public Sans', sans-serif",
  inputBorder: "#e3e3e3",
  inputText: "#28353d",
  inputPlaceholder: "#767676",
  focus: "2px solid #807973",
  boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.2)",
  windowBg: "#fff",
  windowInlineBg: "#f9f9f9",
  badgeBg: "#f1f1f1",
  badgeText: "#7c868c",
  badgeHoverText: "#565d62",
  dotsColor: "#767676",
  btnDisabledBg: "#c8c8c8",
  // these will have to change depending on user selected color
  regularTheme: {
    btnText: "#fff",
    // btnHoverBg: "rgba(91,164,43, 0.5)",
    // headerText: "#fff",
    userMessageText: "#fff",
  },
  inverseTheme: {
    btnText: "#000",
    // btnHoverBg: "rgba(91,164,43, 0.9)",
    // headerText: "#000",
    userMessageText: "#000",
  },
};

function hslToHex(h, s, l) {
  // https://stackoverflow.com/questions/36721830/convert-hsl-to-rgb-and-hex
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0"); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

const hexToCssHsl = (hex, valuesOnly = false) => {
  if (is_debug()) console.log("Analysing colour:", hex);
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  var r = parseInt(result[1], 16);
  var g = parseInt(result[2], 16);
  var b = parseInt(result[3], 16);
  var cssString = "";
  (r /= 255), (g /= 255), (b /= 255);
  var max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  var h,
    s,
    l = (max + min) / 2;
  if (max == min) {
    h = s = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  h = Math.round(h * 360);
  s = Math.round(s * 100);
  l = Math.round(l * 100);

  cssString = h + "," + s + "%," + l + "%";
  cssString = !valuesOnly ? "hsl(" + cssString + ")" : cssString;

  return [[h, s, l], cssString];
};

export const buildTheme = (baseTheme, userSettings) => {
  let theme = { ...baseTheme, ...userSettings };

  // calculate highlight lightness
  const [[h, s, l], cssString] = hexToCssHsl(userSettings.highlight);
  if (is_debug()) console.log("Lightness:", l);

  if (l > userSettings.inverseThreshold) {
    theme = { ...theme, ...theme.inverseTheme };
  } else {
    theme = { ...theme, ...theme.regularTheme };
  }

  // calculate highlight lightness
  const highlightLightness = hexToCssHsl(userSettings.highlight)[0][2];

  if (highlightLightness > 30) {
    theme.btnHoverBg = darken(0.1, theme.highlight);
  } else {
    theme.btnHoverBg = lighten(0.1, theme.highlight);
  }

  // calculate header background lightness
  const backgroundLightness = hexToCssHsl(userSettings.background)[0][2];

  if (backgroundLightness > userSettings.inverseThreshold) {
    theme.headerText = "#212B36";
  } else {
    theme.headerText = "#fff";
  }

  // calculate thresholded link colour
  const linkL = Math.min(l, userSettings.maxLinkLightness);
  theme.linkColour = hslToHex(h, s, linkL);

  const backgroundL = Math.min(l, userSettings.maxBackgroundLightness);
  theme.userMsgBgColour = hslToHex(h, s, backgroundL);

  return theme;
};

// export const getOrCreateStyleTag = (document, frame) =>{
//     const styleId = `style-for-${frame.id}`
// }

export const stylesAreSame = (a, b) => {
  const partsA = a
    .split(";")
    .map((s) => s.trim())
    .filter((s) => s !== "");
  const partsB = b
    .split(";")
    .map((s) => s.trim())
    .filter((s) => s !== "");
  partsA.sort();
  partsB.sort();
  // console.log(partsA);
  // console.log(partsB);

  // console.log(_zip(partsA, partsB).map(([x, y]) => x == y));
  return _zip(partsA, partsB).every(([x, y]) => x == y);
};

export class ClassWatcher {
  constructor(targetNode, classChangedCallback) {
    this.targetNode = targetNode;
    this.classChangedCallback = classChangedCallback;
    this.observer = null;
    //this.lastClassState = targetNode.classList;

    this.init();
  }

  init() {
    this.observer = new MutationObserver(this.mutationCallback);
    this.observe();
  }

  observe() {
    this.observer.observe(this.targetNode, { attributes: true });
  }

  disconnect() {
    this.observer.disconnect();
  }

  mutationCallback = (mutationsList) => {
    for (let mutation of mutationsList) {
      if (
        mutation.type === "attributes" &&
        mutation.attributeName === "class"
      ) {
        this.classChangedCallback(this.targetNode);
      }
    }
  };
}

export const propagateStoreChange = (store) => {
  let currentIsOpen = false;
  let currentIsFeaturesOpen = false;

  return () => {
    // isOpen
    let prevIsOpen = currentIsOpen;
    currentIsOpen = selectIsOpen(store.getState());
    if (prevIsOpen != currentIsOpen) {
      window.parent.postMessage(
        {
          channelName,
          name: "isOpen",
          data: currentIsOpen,
        },
        "*"
      );
    }

    // isFeaturesOpen
    let prevIsFeaturesOpen = currentIsFeaturesOpen;
    currentIsFeaturesOpen = selectIsFeaturesOpen(store.getState());
    if (prevIsFeaturesOpen != currentIsFeaturesOpen) {
      window.parent.postMessage(
        {
          channelName,
          name: "isFeaturesOpen",
          data: currentIsFeaturesOpen,
        },
        "*"
      );
    }
  };
};

export const markLoadedInStore = (store) => (mode) => {
  store.dispatch(setFrameLoaded(mode));
  window.parent.postMessage(
    {
      channelName,
      name: "frameLoaded",
      data: mode,
    },
    "*"
  );
};

// calculate frame positions
// parent page load

export const calculateFramePositionsAndVisibility = (
  frames,
  theme,
  store,
  debug
) => {
  let messagesClassWatcher = null;
  let fqsClassWatcher = null;
  let imtClassWatcher = null;
  return () => {
    const FRAME_STYLE = (zIndex) => `border: 0px !important; 
    overflow: hidden !important;
    color-scheme: none !important;
    background: none !important;
    z-index: ${zIndex} !important;
    transition: opacity 0.3s ease 0s;`;
    // set trigger position

    if (debug) console.log("Pos and vis triggered");

    const mobileMq = window.matchMedia("(max-width: 490px)");

    const chatOuterSpacingResponsive = mobileMq.matches
      ? 20
      : theme.chatOuterSpacing;

    (() => {
      const frame = frames.trigger;
      if (!frame) return;

      const posHorizontal =
        theme.chatAlignment === "right"
          ? `right: ${chatOuterSpacingResponsive}px`
          : `left: ${chatOuterSpacingResponsive}px`;

      const posVertical = `bottom: ${chatOuterSpacingResponsive - 10}px`;
      const frameWidth = `width: ${50}px`;
      const frameHeight = `height: ${50 + 10}px`;
      const triggerFrameVisible = selectFrameLoaded(
        store.getState(),
        "trigger"
      );
      const frameVisible = triggerFrameVisible
        ? ``
        : `opacity: 0 !important; pointer-events: none !important`;

      if (triggerFrameVisible) {
        frame.removeAttribute("tabindex");
      } else {
        frame.setAttribute("tabindex", "-1");
      }

      const newStyle = `${FRAME_STYLE(2147482995)}; 
      position: fixed !important; 
      ${posHorizontal} !important; 
      ${posVertical} !important;
      ${frameWidth} !important;
      ${frameHeight} !important;                            
      ${frameVisible};`;

      // compare if applying styles is needed

      const curStyle = frame.getAttribute("style");
      if (!stylesAreSame(curStyle, newStyle)) {
        if (debug) console.log("Applying trigger style");
        // console.log(frame.getAttribute("style"), newStyle);
        frame.style = newStyle;
      }
    })();

    // set messages positions and visibility
    (() => {
      const frame = frames.messages;
      if (!frame) return;
      const messagesFrameVisible =
        selectFrameLoaded(store.getState(), "messages") &&
        selectIsOpen(store.getState());
      const frameVisible = messagesFrameVisible
        ? ``
        : `opacity: 0 !important; pointer-events: none !important`;

      if (messagesFrameVisible) {
        frame.removeAttribute("tabindex");
      } else {
        frame.setAttribute("tabindex", "-1");
      }

      // now inject or refresh styles
      const frameMessagesStyle = `${FRAME_STYLE(2147482997)};
        ${
          theme.chatType === "overlay" || selectIsOpen(store.getState())
            ? "position: fixed !important;"
            : ""
        }`;

      const messagesEl = document.querySelector(`div.${frame.id}`);
      const messagesClass = messagesEl?.getAttribute("class");
      if (messagesClass) {
        if (!messagesClassWatcher) {
          frame.style = frameMessagesStyle;
          frame.setAttribute("class", messagesEl.getAttribute("class"));
          messagesClassWatcher = new ClassWatcher(messagesEl, (targetNode) => {
            if (debug) console.log("Messages class changed");

            const targetClass = targetNode.getAttribute("class");
            if (targetClass !== frame.getAttribute("class")) {
              if (debug) console.log("Applying messages style");
              frame.setAttribute("class", targetClass);
            }
            frame.style = frameMessagesStyle; // + ";opacity: 1;";
          });
        }
      } else {
        frame.style = frameMessagesStyle + ";opacity: 0;";
      }
    })();

    // set fqs styles and height
    (() => {
      const frame = frames.fqs;
      if (!frame) return;

      const fqFramevisible =
        selectFrameLoaded(store.getState(), "fqs") &&
        selectIsFeaturesOpen(store.getState());
      const frameVisible = fqFramevisible
        ? ``
        : `opacity: 0 !important; pointer-events: none !important`;

      if (fqFramevisible) {
        frame.removeAttribute("tabindex");
      } else {
        frame.setAttribute("tabindex", "-1");
      }

      // now inject or refresh styles
      const frameFQSStyle = () => {
        const frameSize = selectFrameSize(store.getState(), "fqs");
        if (debug)
          console.log(
            "Frame height",
            frameSize.height,
            Math.max(10, frameSize.height)
          );
        return `${FRAME_STYLE(2147482997)};
          position: fixed !important;
          height: ${Math.max(20, frameSize.height)}px !important;`;
      };

      const fqsEl = document.querySelector(`div.${frame.id}`);
      const fqsClass = fqsEl?.getAttribute("class");
      if (debug) console.log("FQ class", fqsClass);
      if (fqsClass) {
        if (fqFramevisible) {
          frame.style = frameFQSStyle();
        } else {
          frame.style = frameFQSStyle() + ";opacity: 0;";
        }
        if (!fqsClassWatcher) {
          frame.setAttribute("class", fqsEl.getAttribute("class"));
          fqsClassWatcher = new ClassWatcher(fqsEl, (targetNode) => {
            if (debug) console.log("FQs class changed");
            frame.setAttribute("class", targetNode.getAttribute("class"));
          });
        }
      } else {
        frame.style = frameFQSStyle() + ";opacity: 0;";
      }
    })();

    // set imt styles and height
    (() => {
      const frame = frames.imt;
      if (!frame) return;

      const imtFramevisible = selectFrameLoaded(store.getState(), "imt");
      const frameVisible = imtFramevisible
        ? ``
        : `opacity: 0 !important; pointer-events: none !important`;

      if (imtFramevisible) {
        frame.removeAttribute("tabindex");
      } else {
        frame.setAttribute("tabindex", "-1");
      }

      // now inject or refresh styles, using correct element height
      const frameFQSStyle = () => {
        const frameSize = selectFrameSize(store.getState(), "imt");
        if (debug)
          console.log(
            "Frame height",
            frameSize.height,
            Math.max(10, frameSize.height)
          );
        return `${FRAME_STYLE(2147482997)};
            // position: fixed !important;
            width: 100%;
            height: ${Math.max(150, frameSize.height)}px !important;`;
      };

      const imtEl = document.querySelector(`div.${frame.id}`);
      const imtClass = imtEl?.getAttribute("class");
      if (imtClass) {
        frame.style = frameFQSStyle();
        if (!imtClassWatcher) {
          frame.setAttribute("class", imtEl.getAttribute("class"));
          imtClassWatcher = new ClassWatcher(imtEl, (targetNode) => {
            if (debug) console.log("IMT class changed");
            frame.setAttribute("class", targetNode.getAttribute("class"));
          });
        }
      } else {
        frame.style = frameFQSStyle() + ";opacity: 0;";
      }
    })();
  };
};
