import styled from "@emotion/styled";

// Example of inheritance

export const GenericMessageStyle = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin: 10px 0 10px;
  flex-shrink: 0;

  .chat-bot-icon {
    width: 30px;
    margin-right: 20px;
    flex-shrink: 0;
  }
  .chat-bubble {
    width: 260px;
    padding: 15px 20px 19px 20px;
    min-height: 50px;
    border-radius: 4px;
    font-family: ${(props) => props.theme.font};
    font-size: 14px;
    line-height: 1.29;
    box-sizing: border-box;
    ${(props) =>
      props.theme.chatType === "inline" &&
      `
      @media (min-width: 491px) {
        width: 100%;
        max-width: 300px;
      }
    `};
  }
  p {
    margin: 0 0 17px;
    &:last-of-type:not(:first-of-type) {
      margin-bottom: 0;
    }
    &:last-child {
      margin-bottom: 0;
      margin-block-end: 0px;
    }
  }
  b {
    letter-spacing: 0.11px;
  }
  a {
    color: ${(props) => props.theme.linkColour};
    text-decoration: underline ${(props) => props.theme.linkColour};
    cursor: pointer;
    font-size: inherit;
    line-height: 1;
    -webkit-transition: color 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, opacity 0.3s ease-in-out;
    outline-offset: 1px;
    &:hover {
      color: ${(props) => props.theme.linkColour};
      -webkit-text-decoration-color: transparent;
      text-decoration-color: transparent;
    }
    &:active,
    &:hover {
      outline-width: 0;
    }
    &:focus {
      -webkit-text-decoration-color: transparent;
      text-decoration-color: transparent;
      color: ${(props) => props.theme.linkColour};
      outline: unset;
      -webkit-box-shadow: unset;
      box-shadow: unset;
    }
    &:focus-visible {
      outline: ${(props) => props.theme.focus};
      outline-offset: 1px;
    }
  }
`;

export const UserMessageStyle = styled(GenericMessageStyle)`
  .chat-bubble {
    background-color: ${(props) => props.theme.userMsgBgColour};
    color: ${(props) => props.theme.userMessageText};
  }
  align-self: flex-end;
`;

export const SystemMessageStyle = styled(GenericMessageStyle)`
  width: 100%;
  .chat-bubble {
    background-color: ${(props) => props.theme.systemMessageBg};
    color: ${(props) => props.theme.systemMessageText};

    p:last-child {
    }
  }
`;
