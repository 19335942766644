import styled from "@emotion/styled";
import { useEffect, useRef } from "react";

// ----------------------------------------------------------------------

import TestMessage from "./messages/TestMessage.js";
import SearchResultsMessage from "./messages/SearchResultsMessage.js";
import WelcomeMessage from "./messages/WelcomeMessage.js";
import UserResponseMessage from "./messages/UserResponseMessage.js";
import CompletionMessage from "./messages/CompletionMessage.js";
import BinaryResponseMessage from "./messages/BinaryResponseMessage.js";
import SystemResponseMessage from "./messages/SystemResponseMessage.js";
import GroupSelectionMessage from "./messages/GroupSelectionMessage.js";
import LoadingResponse from "./messages/LoadingResponse.js";
import Badge from "./Badge.js";

const ChatMessagesContainerStyle = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  overscroll-behavior: none;
`;

const ChatMessagesInnerStyle = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 620px;
  margin: 0 auto;
  /* - badge height and margin */
  min-height: calc(100% - 40px);
  align-items: flex-start;
  padding: 10px 20px 0 20px;
`;

export default function ChatMessages(props) {
  const { messages, interimSpinner, questions, showQuestions, isProcessing } =
    props;
  const ref = useRef(null);
  const bottomRef = useRef(null);

  useEffect(() => {
    // info about container size
    // console.log(ref.current?.clientWidth);
    // console.log(ref.current?.clientHeight);

    // scroll to the bottom
    bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
  }, [messages.length, messages.at(-1)]);

  return (
    <ChatMessagesContainerStyle ref={bottomRef}>
      <ChatMessagesInnerStyle ref={ref}>
        {messages.map((element, index) => {
          //console.log(element);
          if (element.type == "TestMessage") {
            return <TestMessage key={index}>{element.text}</TestMessage>;
          } else if (element.type == "SearchResultsMessage") {
            return (
              <SearchResultsMessage
                key={index}
                articles={element.articles}
                flagged={element.flagged}
              ></SearchResultsMessage>
            );
          } else if (element.type == "WelcomeMessage") {
            return (
              <WelcomeMessage
                key={index}
                variant={element.variant}
                isRelevant={index == messages.length - 1}
                hideIntro={element.hideIntro}
                showQuestions={showQuestions}
                questions={questions}
                isProcessing={isProcessing}
              ></WelcomeMessage>
            );
          } else if (element.type == "UserResponseMessage") {
            return (
              <UserResponseMessage
                key={index}
                text={element.text}
              ></UserResponseMessage>
            );
          } else if (element.type == "CompletionMessage") {
            return (
              <CompletionMessage
                key={index}
                isFinished={element.isFinished}
                noArticlesFound={element.noArticlesFound}
                answer={element.answer}
                sourceQuestion={element.sourceQuestion}
              ></CompletionMessage>
            );
          } else if (element.type == "BinaryResponseMessage") {
            return (
              <BinaryResponseMessage
                key={index}
                isRelevant={index == messages.length - 1}
              ></BinaryResponseMessage>
            );
          } else if (element.type == "GroupSelectionMessage") {
            return (
              <GroupSelectionMessage
                key={index}
                isRelevant={index == messages.length - 1}
                groups={element.groups}
              ></GroupSelectionMessage>
            );
          } else if (element.type == "SystemResponseMessage") {
            return (
              <SystemResponseMessage
                key={index}
                text={element.text}
                textHTML={element.textHTML}
                textHTMLNoAgents={element.textHTMLNoAgents}
                textElements={element.textElements}
                preset={element.preset}
                isRelevant={index == messages.length - 1}
              ></SystemResponseMessage>
            );
          } else {
            throw new Error("Invalid type " + element.type);
          }
        })}
        {/* Here should be the spinner */}
        {interimSpinner && <LoadingResponse />}
      </ChatMessagesInnerStyle>
      <Badge />
    </ChatMessagesContainerStyle>
  );
}
