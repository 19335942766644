export default function Arrow() {
  return (
    <svg width="7px" height="13px" viewBox="0 0 7 13">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1382, -765)" fill="#FFFFFF">
          <g transform="translate(1020, 194)">
            <g transform="translate(0, 546)">
              <g transform="translate(337, 16)">
                <path d="M25.2104959,9.22900694 C25.4982328,8.93104664 25.9730342,8.92275895 26.2709945,9.21049587 L31.666043,14.4204336 C31.8309109,14.5796447 31.9070912,14.7961284 31.8935079,15.0082362 C31.9068503,15.2198363 31.8306504,15.4358876 31.666043,15.5948472 L26.2709945,20.804785 C25.9730342,21.0925219 25.4982328,21.0842342 25.2104959,20.7862739 C24.9227589,20.4883136 24.9310466,20.0135122 25.2290069,19.7257753 L30.115,15.007 L25.2290069,10.2895056 C24.9581339,10.0279266 24.9266598,9.61174878 25.1393568,9.31437992 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
