export default function BotFace() {
  return (
    <svg height="34" viewBox="0 0 30 34" width="30" className="chat-bot-icon">
      <g fill="none" fillRule="evenodd">
        <g fill="#767676">
          <path
            d="m30 29.7035502v4.2964498h-6z"
            transform="matrix(1 0 0 -1 0 63.7036)"
          />
          <path d="m4 0h22c2.209139 0 4 1.790861 4 4v26h-26c-2.209139 0-4-1.790861-4-4v-22c0-2.209139 1.790861-4 4-4z" />
        </g>
        <g fill="#fff">
          <rect height="4" rx="1" width="4" x="10" y="13" />
          <rect height="4" rx="1" width="4" x="16" y="13" />
        </g>
      </g>
    </svg>
  );
}
