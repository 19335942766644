import { useState, useRef, useEffect, useCallback } from "react";
// import { useWeavy, WyMessenger } from "@weavy/uikit-react";
import styled from "@emotion/styled";
import { Icon } from "@iconify/react";

const ChatWindowBackground = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 2147482998;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
`;

const ChatWindowStyle = styled.div`
  overscroll-behavior: contain;
  left: 0px;
  top: calc(100vh / 2 - 600px / 2);
  z-index: 2147482999;
  width: 100%;
  position: fixed;
  background-color: white;
  height: 600px;
  max-height: calc(100vh - 60px);
  max-height: calc(100svh - 60px);
  @media (min-width: 1200px) {
    max-width: 1200px;
    left: calc(100% / 2 - 1200px / 2);
  }
  box-sizing: border-box;
  @media (min-width: 491px) and (max-height: 660px) {
    top: 5px;
    max-height: calc(100vh - 20px);
  }

  @media (max-width: 490px) {
    top: 0px;
    height: 100vh;
    max-height: 100dvh;
    height: 100dvh;
  }
  padding-top: 38px;
  background-color: #2a2a2a;
`;

const ChatWindowClose = styled.div`
  position: absolute;
  right: -6px;
  top: 4px;
  width: 40px;
  height: 40px;
  // background-color: blue;
  z-index: 9999;
  cursor: pointer;
`;

const IframeContainer = styled.div`
  overflow: hidden;
  height: 100%;
  iframe {
    margin-top: -50px;
    height: calc(100% + 50px);
    width: 100%;
    border: 0;
  }
  // border: 1px solid red;
`;

export default function Chat42Q(props) {
  // const weavy = useWeavy({
  //   url: "https://myenvironment.weavy.io",
  //   tokenUrl: "https://myserver.test/api/token",
  // });

  const [isOpen, setIsOpen] = useState(false);

  const handleEvent = useCallback((event) => {
    setIsOpen(true);
  }, []);

  useEffect(() => {
    document.addEventListener("show-42q-chat", handleEvent);
    return () => {
      document.removeEventListener("show-42q-chat", handleEvent);
    };
  }, [handleEvent]);

  return (
    <>
      {isOpen && (
        <ChatWindowBackground
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <ChatWindowStyle>
            <IframeContainer>
              <iframe src="https://go.crisp.chat/chat/embed/?website_id=4eac88ef-4a83-4875-8139-ad89345a7647" />
            </IframeContainer>
            <ChatWindowClose tabindex={1} onClick={() => setIsOpen(false)}>
              <Icon
                icon="material-symbols:close"
                width={30}
                style={{ color: "white" }}
              />
            </ChatWindowClose>
          </ChatWindowStyle>
        </ChatWindowBackground>
      )}
    </>
  );
}
