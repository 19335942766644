import React from "react";
import ReactDOM from "react-dom/client";
import { throttle as _throttle, debounce as _debounce } from "lodash";
import { Provider } from "react-redux";

import ParentStylesShim from "../components/ParentStylesShim.js";
import App from "../App.js";
import Chat42Q from "../Chat42Q.js";
import { setUpInterceptor } from "../services/http.js";
import makeStore from "../store/index.js";

import {
  uuidv4,
  buildTheme,
  THEME_42Q_TEST,
  injectIframeHTML,
  calculateFramePositionsAndVisibility,
} from "./widget.js";

import {
  setIsOpen,
  setIsFeaturesOpen,
  setFrameLoaded,
  setFrameSize,
} from "../features/widgetStateSlice.js";

const dateString = new Date().toISOString().split("T")[0];
const idSuffix = uuidv4();
const TAB_SPECIFIC = true; // needs to be true for now - controller and command queue does not work across tabs

window.fourtyTwoQDataLayer = window.fourtyTwoQDataLayer || [];
function fourtTwoQJS() {
  fourtyTwoQDataLayer.push(arguments);
}

export const settingsReady = (
  GLOB_DEBUG,
  currentScript,
  settings,
  chatMode
) => {
  const channelName = `42q__mock_app_id_${dateString}_${settings.appId}${
    TAB_SPECIFIC ? uuidv4() : ""
  }`;

  if (GLOB_DEBUG)
    console.log(
      "Chat status",
      settings.active,
      settings.activeCreditSubscription
    );

  const showChat =
    chatMode === "demo" ||
    (settings.active && settings.activeCreditSubscription);

  if (!showChat && settings.chatType === "overlay") {
    // what do we do here for the inline?
    console.log("Chat not active, not showing");
    return;
  }

  settings.previewChatMode = chatMode;

  const parentChannelName = channelName + "__parent";
  const store = makeStore(parentChannelName);
  setUpInterceptor(store);

  if (GLOB_DEBUG) console.log("Talking on channel", channelName);

  // get script SRC
  if (GLOB_DEBUG) console.log("Current script", currentScript);
  const widgetDiv = document.querySelector("div.widget_42q");
  if (widgetDiv) widgetDiv.innerHTML = "";
  if (widgetDiv) widgetDiv.style = "font-size: 0 !important;";

  if (!widgetDiv & (settings.chatType === "inline")) {
    console.log(
      "42q error: Incorrect setup. Please make sure a div with class widget_42q is present when using the inline version of the chat widget."
    );
    throw new Error("Missing div with a class widget_42q");
  }

  const theme = buildTheme(THEME_42Q_TEST, settings);
  const widgetSrc = currentScript.src;
  // const widgetSrc = "http://y:3000/static/js/bundle.js";

  if (GLOB_DEBUG) console.log("Widget script source:", widgetSrc);

  // be ready for settings transfer payload
  window.addEventListener("message", function (event) {
    const payload = event.data;

    if (payload.channelName === channelName) {
      if (GLOB_DEBUG) console.log("Message received from the child: ", payload); // Message received from child

      if (payload.name === "isOpen") {
        store.dispatch(setIsOpen(payload.data));
      } else if (payload.name === "isFeaturesOpen") {
        store.dispatch(setIsFeaturesOpen(payload.data));
      } else if (payload.name === "frameLoaded") {
        store.dispatch(setFrameLoaded(payload.data));

        // send out window sizes
        sendWindowSizeToFrames();
      } else if (payload.name === "frameSize") {
        store.dispatch(setFrameSize(payload.data));
      } else if (payload.name === "open42QChat") {
        document.dispatchEvent(new Event("show-42q-chat"));
      } else if (payload.name === "settingsRequest") {
        const frame = frames[payload.mode];
        if (GLOB_DEBUG)
          console.log(
            "Sending message to the child...",
            payload.mode,
            frame.contentWindow
          );
        frame.contentWindow.postMessage(
          { name: "settings", channelName, data: settings },
          "*"
        );
      } else {
        throw new Error(`Invalid payload ${payload.name}`);
      }
    }
  });

  const frames = {
    messages: injectIframeHTML(
      "messages",
      channelName,
      settings.chatType,
      widgetSrc,
      idSuffix,
      settings.chatType === "inline" ? widgetDiv : null,
      chatMode,
      GLOB_DEBUG
    ),
  };
  if (settings.chatType === "overlay") {
    frames.trigger = injectIframeHTML(
      "trigger",
      channelName,
      settings.chatType,
      widgetSrc,
      idSuffix,
      null,
      chatMode,
      GLOB_DEBUG
    );
    if (settings.showQuestions === true) {
      frames.fqs = injectIframeHTML(
        "fqs",
        channelName,
        settings.chatType,
        widgetSrc,
        idSuffix,
        widgetDiv,
        chatMode,
        GLOB_DEBUG
      );
    }
  } else {
    // we show the mobile trigger in the fqs frame
    frames.imt = injectIframeHTML(
      "imt",
      channelName,
      settings.chatType,
      widgetSrc,
      idSuffix,
      widgetDiv,
      chatMode,
      GLOB_DEBUG
    );
  }

  // resize observer for each of the frames

  document.addEventListener("42qchat-command-open", () => {
    for (const [frame_name, frame] of Object.entries(frames)) {
      //  console.log("FRAME", frame_name, frame);
      frame.contentWindow.postMessage(
        {
          name: "command-open",
          channelName,
          data: {},
        },
        "*"
      );
    }
  });

  const sendWindowSizeToFrames = _throttle(() => {
    const data = { width: window.innerWidth, height: window.innerHeight };
    // console.log("Window width send", data);
    for (const [frame_name, frame] of Object.entries(frames)) {
      //  console.log("FRAME", frame_name, frame);

      frame.contentWindow.postMessage(
        {
          name: "window_size",
          channelName,
          data: data,
        },
        "*"
      );
    }
  }, 150);

  // TODO make below a bit more selective
  const calcThrottled = _throttle(
    calculateFramePositionsAndVisibility(frames, theme, store, GLOB_DEBUG),
    150
  );

  const calcDebounced = _debounce(calcThrottled, 200);

  const parentWindowObserverFun = _throttle((entries) => {
    /** sends width only */
    if (entries.length === 0) return;
    // Do what you want to do when the size of the element changes

    // const [width, height] = [
    //   entries[0].contentRect.width,
    //   entries[0].contentRect.height,
    // ];
    sendWindowSizeToFrames();
    if (GLOB_DEBUG) console.log("Window width", window.innerWidth);

    window.requestAnimationFrame(calcDebounced);
  }, 100);

  const windowResizeObserver = new ResizeObserver(parentWindowObserverFun);
  windowResizeObserver.observe(document.body);

  // send a message now
  sendWindowSizeToFrames();

  // TODO - encode user settings in a JSON and add them to the URL

  // max-height: 100% !important;`;

  store.subscribe(calcThrottled);
  calcThrottled();
  // update store based on sent/received messages

  // we will just append ourselves to the body,
  // we are not in any way dependent on the presence of the root element
  const rootDiv = document.createElement("div");
  document.body.appendChild(rootDiv);
  const root = ReactDOM.createRoot(rootDiv);
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <Chat42Q />
        <App frameMode="master" theme={theme}>
          <ParentStylesShim frames={frames} theme={theme} />
        </App>
      </Provider>
    </React.StrictMode>
  );

  // set up listeners for our datalayer object
  const processCustomEvent = (args) => {
    const customEvent = args[0];
    if (GLOB_DEBUG) console.log("42Q Custom event processing", customEvent);
    //
    if (customEvent.name === "42q-chatwindow-open") {
      let counter = 0;
      let loadedChecker = setInterval(() => {
        if (counter > 30) clearInterval(loadedChecker); // disable after 15s wait
        const state = store.getState();
        if (state.widgetState.loadedFrames.messages === true) {
          clearInterval(loadedChecker);
          if (GLOB_DEBUG) console.log("Messages loaded, issuing command");
          for (const [frame_name, frame] of Object.entries(frames)) {
            //  console.log("FRAME", frame_name, frame);
            frame.contentWindow.postMessage(
              {
                name: "command-open",
                channelName,
                data: {},
              },
              "*"
            );
          }
        } else {
          if (GLOB_DEBUG) console.log("Messages not loaded yet, waiting");
        }
      }, 500);
    } else if (customEvent.name === "42q-chatwithus-open") {
      if (GLOB_DEBUG) console.log("Launching chat");
      setTimeout(() => {
        document.dispatchEvent(new Event("show-42q-chat"));
      }, 1500);
    }
  };

  window.fourtyTwoQDataLayer.push = processCustomEvent;

  // process
  [...window.fourtyTwoQDataLayer].forEach((customEvent) => {
    console.log("42Q Custom event DELAYED", customEvent);
    processCustomEvent(customEvent);
  });
};
