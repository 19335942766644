import { useState, useRef, useEffect, useMemo } from "react";
import { throttle as _throttle } from "lodash";

// import useResizeObserver from "use-resize-observer";
import { ThemeProvider } from "@emotion/react";
import styled from "@emotion/styled";
import { useDispatch, useSelector, useStore } from "react-redux";

import ChatTrigger from "./components/ChatTrigger.js";
import ChatHeader from "./components/chatwindow/ChatHeader.js";
import ChatMessages from "./components/chatwindow/ChatMessages.js";
import ChatInput from "./components/chatwindow/ChatInput.js";
import FQTitle from "./components/fq/FQTitle.js";
import FQList from "./components/fq/FQList.js";
import ChatTriggerInline from "./components/ChatTriggerInline.js";
import WidgetInactive from "./components/WidgetInactive.js";

import { setIsOpen, addToQueue } from "./features/widgetStateSlice.js";
import { MASTER_MODE } from "./utils/widget.js";

import MessagesController, {
  EVENT_INIT_NORMAL,
  EVENT_USER_INPUT,
  EVENT_INIT_RESTORE,
} from "./utils/controller.js";

export const ChatFrame = styled.div`
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ToggleThemeStyle = styled.div`
  left: 20px;
  bottom: 90px;
  z-index: 9999;
  width: 300px;
  position: fixed;
`;

const OutageSelectorStyle = styled.div`
  left: 20px;
  bottom: 115px;
  z-index: 9999;
  width: 300px;
  position: fixed;
`;

export default function App(props) {
  const { inTheme, gVariant, frameMode, theme, children, debug } = props;
  const dispatch = useDispatch();
  const store = useStore();

  const widgetState = useSelector((state) => state.widgetState);
  const isOpen = useSelector((state) => state.widgetState.isOpen);

  const containerRef = useRef(null);

  // const theme = useMemo(
  //   () => buildTheme(THEME_42Q, USER_SETTINGS_DEFAULTS),
  //   [THEME_42Q, USER_SETTINGS_DEFAULTS]
  // );
  const [isProcessing, setIsProcessing] = useState(false);
  const [allowUserInput, setAllowUserInput] = useState(false);

  const [controller, setController] = useState(null);

  // const themeChange = (e) => {
  //   console.log(e.target.value);
  //   if (e.target.value == "default") {
  //     setTheme(buildTheme(THEME_42Q, USER_SETTINGS_DEFAULTS));
  //   }
  //   if (e.target.value == "test") {
  //     setTheme(buildTheme(THEME_42Q_TEST, USER_SETTINGS_DEFAULTS));
  //   }
  // };

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  });

  if (frameMode === MASTER_MODE) {
    useEffect(() => {
      const listenerFun = function (event) {
        const payload = event.data;
        if (payload.channelName === widgetState.channelName) {
          if (payload.name === "command-open") {
            // console.log("Window size yay");
            dispatch(setIsOpen(true));
          }
        }
      };

      window.addEventListener("message", listenerFun);

      return () => {
        window.removeEventListener("message", listenerFun);
      };
    });
  }

  if (frameMode !== "master") {
    // this is framed load

    useEffect(() => {
      const listenerFun = function (event) {
        const payload = event.data;

        if (payload.channelName === widgetState.channelName) {
          if (payload.name === "window_size") {
            // console.log("Window size yay");

            setWindowSize({
              width: payload.data.width,
              height: payload.data.height,
            });
          }
        }
      };

      window.addEventListener("message", listenerFun);

      return () => {
        window.removeEventListener("message", listenerFun);
      };
    });
  }

  if (frameMode === "fqs" || frameMode === "imt") {
    useEffect(() => {
      const observerFun = _throttle((entries) => {
        if (entries.length === 0) return;
        // Do what you want to do when the size of the element changes
        const height = entries[0].contentRect.height;
        if (debug) console.log(`Resized ${frameMode}`, height);
        window.parent.postMessage(
          {
            channelName: widgetState.channelName,
            name: "frameSize",
            data: {
              name: frameMode,
              width: 0, // set in the parent
              height: height,
            },
          },
          "*"
        );
      }, 100);

      if (!containerRef.current) return;
      const resizeObserver = new ResizeObserver(observerFun);
      resizeObserver.observe(containerRef.current);
      return () => resizeObserver.disconnect(); // clean up
    }, []);
  }

  const [messages, setMessages] = useState([]);

  useEffect(() => {
    /**
     *
     * Considerations:
     * - only serialise when isProcessing is false?
     */

    if (frameMode === MASTER_MODE && controller && !isProcessing) {
      // console.log("Messages changed, dumping state...");
      const statePayload = {
        messages: messages,
        controller: {
          selectedSilo: controller.getSelectedSilo(),
          state: controller.getState(),
        },
        ui: {
          allowUserInput: allowUserInput,
        },
        date: new Date().toISOString(),
      };
      // console.log(statePayload);
      // console.log(JSON.stringify(statePayload));

      // // now do a test store
      // window.localStorage.setItem(
      //   "42q_chat_state_12345",
      //   JSON.stringify(statePayload)
      // );
    }
  }, [isProcessing, messages.length, messages.at(-1)]);

  let [firedMessageShow, setFiredMessageShow] = useState(false);
  useEffect(() => {
    if (frameMode === MASTER_MODE) {
      // if (windowSize.width > 100 && windowSize.width <= 490) {
      //   setFiredMessageShow(true);
      //   // noop otherwise
      // } else
      if (
        theme.chatType === "overlay" &&
        theme.visibilityWidgetWindow &&
        !theme.showQuestions &&
        windowSize.width > 490 &&
        !firedMessageShow
      ) {
        // pre-open messages when overlay and NO feature questions are shown

        setFiredMessageShow(true);
        dispatch(setIsOpen(true));
      }
    }
  }, [theme, windowSize, setFiredMessageShow, dispatch]);

  useEffect(() => {
    if (frameMode === MASTER_MODE) {
      const locController = new MessagesController(
        store,
        theme,
        frameMode,
        messages,
        setMessages,
        setIsProcessing,
        setAllowUserInput,
        null,
        debug
      );
      setController(locController);

      // check - anything to restore?

      // dispatch default init
      //if (false) {
      dispatch(addToQueue({ name: EVENT_INIT_NORMAL }));

      // dispatch(
      //   addToQueue({
      //     name: EVENT_INIT_RESTORE,
      //     payload: JSON.parse(
      //       window.localStorage.getItem("42q_chat_state_12345")
      //     ),
      //   })
      // );

      // state restoration

      /* 
      dispatch(addToQueue({ name: EVENT_INIT_RESTORE, selectedSilo: 1, state: "test" })); 
      // restore messages
      // restore UI setAllowUserInput
      
      */

      // } else {
      //   dispatch(addToQueue({ name: EVENT_INIT_SILOS }));
      // }

      return () => {
        locController.unsubscribe();
      };
    } else {
      return () => {};
    }
  }, [frameMode, setIsProcessing, setMessages]);

  if (MASTER_MODE !== "messages") {
    throw new Error("Expected the messages frame to be the master");
  }

  return (
    <ThemeProvider theme={theme}>
      {frameMode === "trigger" && (
        <ChatTrigger
          onClick={() => dispatch(setIsOpen(!widgetState.isOpen))}
          isOpen={isOpen}
          inFrame={true}
        />
      )}
      {frameMode === "messages" && (
        <ChatFrame>
          {theme.chatType === "inline" &&
          theme.previewChatMode === "live" &&
          !(theme.active && theme.activeCreditSubscription) ? (
            <>
              {/* INACTIVE STATE Mode: {theme.previewChatMode}{" "}
              {`${theme.active && theme.activeCreditSubscription}`} */}
              <WidgetInactive />
            </>
          ) : (
            <>
              {(theme.chatType === "overlay" ||
                theme.visibilityWidgetWindowHeader ||
                (windowSize.width > 100 && windowSize.width <= 490)) && (
                <ChatHeader
                  withButton={
                    theme.buttonText &&
                    theme.handoverButtonLinkHref &&
                    theme.visibilityHandoverButton
                  }
                  windowSize={windowSize}
                />
              )}

              <ChatMessages
                messages={messages}
                agentsAvailable={true}
                interimSpinner={isProcessing}
                questions={{ questions: theme.featuredQuestions }}
                showQuestions={
                  theme.showQuestions && theme.chatType === "inline"
                }
                isProcessing={isProcessing || !allowUserInput}
              />
              <ChatInput
                handleClick={(question) => {
                  dispatch(
                    addToQueue({ name: EVENT_USER_INPUT, text: question })
                  );
                }}
                isProcessing={isProcessing || !allowUserInput}
                debug={debug}
              />
              {/* <button
                onClick={() => {
                  dispatch(
                    addToQueue({
                      name: EVENT_INIT_RESTORE,
                      payload: JSON.parse(
                        window.localStorage.getItem("42q_chat_state_12345")
                      ),
                    })
                  );
                }}
              >
                TEST RESTORE
              </button> */}
            </>
          )}
        </ChatFrame>
      )}
      {frameMode === "imt" && theme.chatType === "inline" && (
        <div ref={containerRef}>
          <ChatTriggerInline
            isOpen={isOpen}
            handleClick={(e) => {
              if (debug) console.log("Button was clicked");
              dispatch(setIsOpen(true));
            }}
            questions={{ questions: theme.featuredQuestions }}
            showQuestions={theme.showQuestions}
          />
        </div>
      )}
      {frameMode === "fqs" && theme.chatType === "overlay" && (
        <div ref={containerRef}>
          <FQTitle title={theme.FQtitle} />
          <FQList questions={{ questions: theme.featuredQuestions }} />
        </div>
      )}
      {frameMode === "master" && <>{children}</>}
    </ThemeProvider>
  );
}
