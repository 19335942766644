import styled from "@emotion/styled";
import Arrow from "./icons/Arrow.js";
// ----------------------------------------------------------------------

const ButtonStyle = styled.button`
  font-family: ${(props) => props.theme.font};
  flex-grow: 0;
  align-self: center;
  background-color: ${(props) => props.theme.highlight};
  color: ${(props) => props.theme.btnText};
  border: 1px solid ${(props) => props.theme.highlight};
  font-size: ${(props) => (props.size == "big" ? "16px" : "13px")};
  line-height: ${(props) => (props.size == "big" ? "16px" : "1.31")};
  min-height: ${(props) => (props.size == "big" ? "40px" : "31px")};
  min-width: ${(props) => (props.size == "big" ? "72px" : "50px")};
  padding: ${(props) => (props.size == "big" ? "11px 20px" : "6px 10.5px")};
  font-weight: bold;
  letter-spacing: 0.1px;
  box-sizing: border-box;
  vertical-align: middle;
  border-style: solid;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s background-color, 0.15s color ease-out,
    0.15s border-color ease-out;
  user-select: none;
  background-clip: border-box;
  border-radius: 4px;
  outline-offset: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 143px;
  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.btnHoverBg};
    color: ${(props) => props.theme.btnText};
    border-color: ${(props) => props.theme.btnHoverBg};
  }
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: ${(props) => props.theme.focus};
  }
  &[disabled] {
    pointer-events: none;
    opacity: 0.4;
    cursor: default;
  }
  ${(props) =>
    props.$sendBtn &&
    `
    color: transparent!important;
    padding: 0;
    min-width: 31px;
    min-height: 31px;
    width: 31px;
    height: 31px;
    position: relative;
    &[disabled] {
      background-color: ${props.theme.btnDisabledBg};
      border-color: ${props.theme.btnDisabledBg};
      opacity: 1;
    }
    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &:not([disabled]) svg g {
      fill: ${props.theme.btnText};
    }
  `};
`;

export default function Button(props) {
  const { size, text, disabled, onClick, sendBtn } = props;
  return (
    <ButtonStyle
      size={size}
      disabled={disabled}
      onClick={onClick}
      $sendBtn={sendBtn}
    >
      {sendBtn && <Arrow />}
      {text}
    </ButtonStyle>
  );
}
